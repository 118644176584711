import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

function AboutUs() {
  const navigate = useNavigate();

  const [activeTab1, setactiveTab1] = useState("1");

  const toggle1 = (tab) => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  };

  const databyid = () => {
    navigate("/chekout");
  };

  useEffect(() => {
    datas();
  }, []);

  const [Service, setService] = useState([]);

  const api = "https://api.ssairconditioners.com/";

  const datas = () => {
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/getamcbyid",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setService(res.data.amc);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
          }
        }
      );
  };

  const trimmedBannerImage = Service.bannerImage
    ? Service.bannerImage.trim()
    : "";

  const imageUrl = trimmedBannerImage ? api + trimmedBannerImage : "";

  const [Contact, setContact] = useState([]);
  const [Projects, setProjects] = useState([]);
  const [Products1, setProducts1] = useState([]);
  const [Services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    datas2();
  }, []);

  const datas2 = () => {
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setContact(res.data.contactus[0]);
            setProducts1(res.data.productcategorys);
            setProjects(res.data.projectcategorys);
            setServices(res.data.services);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  const getid = sessionStorage.getItem("Catid");
  const [splitAC, setSplitAC] = useState([]);
  const [windowAC, setWindowAC] = useState([]);

  const [cart, setCart] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/getamcservicebyid",
        { amcId: getid }
      );

      if (response.status === 200) {
        setSplitAC(response.data.splitac);
        setWindowAC(response.data.windowac);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
      }
    }
  };

  const handleCountUp = (
    array,
    setArray,
    index,
    quantity,
    type,
    serviceType
  ) => {
    addToCart(array[index], type, serviceType);
  };

  useEffect(() => {
    fetchData();
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCart(storedCart);
  }, []);

  useEffect(() => {
    if (cart.length > 0) {
      localStorage.setItem("cart", JSON.stringify(cart));
    }
  }, [cart]);

  const addToCart = (item, type, serviceType) => {
    if (cart.length > 0) {
      const check = getObjectById(cart, item._id, serviceType);

      if (check !== null) {
        const updatedCart = [...cart];
        console.log("auan", updatedCart[check].quantity);

        updatedCart[check] = {
          ...updatedCart[check],
          quantity:
            type === "add"
              ? updatedCart[check].quantity + 1
              : updatedCart[check].quantity - 1,
        };

        if (updatedCart[check].quantity == 0) {
          updatedCart.splice(check, 1);
        }
        setCart(updatedCart);
        localStorage.setItem("cart", JSON.stringify(updatedCart));
      } else {
        const newCartItem = {
          id: item._id,
          name: item.title,
          price: item.price,
          quantity: 1,
          type: serviceType,
        };
        setCart([...cart, newCartItem]);
        localStorage.setItem("cart", JSON.stringify([...cart, newCartItem]));
      }
    } else {
      const newCartItem = {
        id: item._id,
        name: item.title,
        price: item.price,
        quantity: 1,
        type: serviceType,
      };
      setCart([newCartItem]);
      localStorage.setItem("cart", JSON.stringify([newCartItem]));
    }
  };

  const calculateTotalPrice = () => {
    if (cart.length === 0) {
      return 0;
    }

    return cart.reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0);
  };

  const totalCartPrice = calculateTotalPrice();

  localStorage.setItem("totalprice", totalCartPrice);
  localStorage.setItem("coupondis", totalCartPrice);

  function getObjectById(array, targetId, serviceType) {
    var i = 0;
    for (const obj of array) {
      if (obj.id === targetId && serviceType === obj.type) {
        return i;
      }
      i++;
    }
    return null;
  }

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
            <span class="loader"></span>
          </div>
        </>
      ) : (
        <>
          <div className="wrapper">
            <Header
              Services={Services}
              Products={Products1}
              Projects={Projects}
              Contact={Contact}
            />

            <main className="page-content" id="fld">
              <div
                id="tm-about-area"
                className="tm-about-area tm-section  bg-white"
              >
                <div
                  className="page-title-area pt-210 pb-180"
                  style={{ backgroundImage: `url(${imageUrl})` }}
                >
                  <div className="container">
                    <div className="page-title-icon">
                      <i className="flaticon-air-conditioner hero__icon hero__icon1" />
                      <i className="flaticon-heating hero__icon hero__icon2" />
                      <i className="flaticon-vacuum-cleaner hero__icon hero__icon3" />
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="page-title text-center">
                          <h2 className="breadcrumb-title">{Service.title}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container mt-5 mb-5">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <img src={api + Service.image}></img>
                    </div>
                    <div className="col-lg-6">
                      <div className="tm-about-content">
                        <h2 className="text-primary">{Service.title}</h2>
                        <p>{Service.description}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="tm-faq-area" className="tm-question-area tm-section">
                  <div className="container mt-5 mb-5">
                    <div className="row">
                      <div className="card" style={{ background: "#ffffff" }}>
                        <div className="card-body">
                          <div className="col-lg-12">
                            <div className="tm-question-right">
                              <div>
                                <div className="row">
                                  <div className="col-md-6 mb-3">
                                    <h2 className="text-center" style={{textDecoration:"underline",color: "#2858A9"}} >
                                      Service Benefits
                                    </h2>
                                    {splitAC.map((data, index) => (
                                      <>
                                        <h4
                                          key={index}
                                          className="pt-4 text-primary"
                                        >
                                          {index + 1}). {data.title}
                                        </h4>
                                        <span>
                                          {data.benefits.map(
                                            (datas, indexs) => (
                                              <>
                                                <div key={indexs}>
                                                  <p
                                                    style={{ fontSize: "16px" }}
                                                  >
                                                    <i
                                                      className="fa fa-check-circle"
                                                      style={{
                                                        color: "#2858A9",
                                                        paddingRight: "10px",
                                                        fontSize: "20px",
                                                      }}
                                                    ></i>
                                                    {datas.benefits}
                                                  </p>
                                                </div>
                                              </>
                                            )
                                          )}
                                        </span>
                                      </>
                                    ))}
                                  </div>
                                  <div className="col-md-6 mt-3">
                                    <Nav
                                      pills
                                      className="navtab-bg nav-justified "
                                      id="prodetails"
                                    >
                                      <NavItem>
                                        <NavLink
                                          className={classnames({
                                            active: activeTab1 === "1",
                                          })}
                                          onClick={() => {
                                            toggle1("1");
                                          }}
                                          style={{
                                            border: "1px #2858A9 solid",
                                            borderRadius: "5px",
                                            padding: "5px",
                                            margin: "4px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          Split Ac
                                        </NavLink>
                                      </NavItem>

                                      <NavItem>
                                        <NavLink
                                          className={classnames({
                                            active: activeTab1 === "2",
                                          })}
                                          onClick={() => {
                                            toggle1("2");
                                          }}
                                          style={{
                                            border: "1px #2858A9 solid",
                                            borderRadius: "5px",
                                            padding: "5px",
                                            margin: "4px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          Window AC
                                        </NavLink>
                                      </NavItem>
                                    </Nav>

                                    <TabContent
                                      activeTab={activeTab1}
                                      className="pt-3 text-muted"
                                    >
                                      <TabPane tabId="1">
                                        <div
                                          className="card"
                                          style={{
                                            background: "#F7F7F7",
                                          }}
                                        >
                                          <div className="card-body">
                                            {splitAC.map((data, index) => (
                                              <div key={index} className="mb-3">
                                                <div className="row align-items-center">
                                                  <div className="col-md-7 col-sm-7">
                                                    <h4
                                                      style={{
                                                        color: "#2858A9",
                                                      }}
                                                    >
                                                      {index + 1}).{data.title}
                                                    </h4>
                                                    <p className="text-muted">
                                                      {data.description.slice(
                                                        0,
                                                        60
                                                      )}
                                                      ...
                                                    </p>
                                                  </div>
                                                  <div className="text-center col-md-2 col-sm-2">
                                                    <h6> ₹ {data.price} </h6>
                                                  </div>
                                                  <div className="col-md-3 text-center col-sm-2">
                                                    {getObjectById(
                                                      cart,
                                                      data._id,
                                                      "split"
                                                    ) === null ||
                                                    cart[
                                                      getObjectById(
                                                        cart,
                                                        data._id,
                                                        "split"
                                                      )
                                                    ].quantity === 0 ? (
                                                      <button
                                                        type="button"
                                                        className="btn btn-outline-primary"
                                                        onClick={() =>
                                                          handleCountUp(
                                                            splitAC,
                                                            setSplitAC,
                                                            index,
                                                            data.quantity,
                                                            "add",
                                                            "split"
                                                          )
                                                        }
                                                      >
                                                        Add +
                                                      </button>
                                                    ) : (
                                                      <div className="quantity-control">
                                                        <button
                                                          type="button"
                                                          className="btn btn-danger btn-sm m-1"
                                                          onClick={() =>
                                                            handleCountUp(
                                                              splitAC,
                                                              setSplitAC,
                                                              index,
                                                              data.quantity,
                                                              "minus",
                                                              "split"
                                                            )
                                                          }
                                                        >
                                                          -
                                                        </button>
                                                        <span className="quantity m-2">
                                                          {cart.length > 0
                                                            ? cart[
                                                                getObjectById(
                                                                  cart,
                                                                  data._id,
                                                                  "split"
                                                                )
                                                              ].quantity
                                                            : 0}
                                                        </span>
                                                        <button
                                                          type="button"
                                                          className="btn btn-primary btn-sm m-1"
                                                          onClick={() =>
                                                            handleCountUp(
                                                              splitAC,
                                                              setSplitAC,
                                                              index,
                                                              data.quantity,
                                                              "add",
                                                              "split"
                                                            )
                                                          }
                                                        >
                                                          +
                                                        </button>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                                <hr className="mt-3" />
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </TabPane>

                                      <TabPane tabId="2">
                                        <div className="container ">
                                          <div className="card">
                                            <div className="card-body">
                                              {windowAC.map((wdata, index) => (
                                                <div
                                                  key={index}
                                                  className="mb-3"
                                                >
                                                  <div className="row align-items-center">
                                                    <div className="col-md-7 col-sm-7">
                                                      <h4
                                                        style={{
                                                          color: "#2858A9",
                                                        }}
                                                      >
                                                        {wdata.title}
                                                      </h4>
                                                      <p className="text-muted">
                                                        {wdata.description.slice(
                                                          0,
                                                          60
                                                        )}
                                                        ...
                                                      </p>
                                                    </div>
                                                    <div className="text-center col-md-2 col-sm-2">
                                                      <h6> ₹ {wdata.price} </h6>
                                                    </div>
                                                    <div className="col-md-3 text-center col-sm-2">
                                                      {getObjectById(
                                                        cart,
                                                        wdata._id,
                                                        "window"
                                                      ) === null ||
                                                      cart[
                                                        getObjectById(
                                                          cart,
                                                          wdata._id,
                                                          "window"
                                                        )
                                                      ].quantity === 0 ? (
                                                        <button
                                                          type="button"
                                                          className="btn btn-outline-primary"
                                                          onClick={() =>
                                                            handleCountUp(
                                                              windowAC,
                                                              setWindowAC,
                                                              index,
                                                              wdata.quantity,
                                                              "add",
                                                              "window"
                                                            )
                                                          }
                                                        >
                                                          Add +
                                                        </button>
                                                      ) : (
                                                        <div className="quantity-control">
                                                          <button
                                                            type="button"
                                                            className="btn btn-danger btn-sm m-1"
                                                            onClick={() =>
                                                              handleCountUp(
                                                                windowAC,
                                                                setWindowAC,
                                                                index,
                                                                wdata.quantity,
                                                                "minus",
                                                                "window"
                                                              )
                                                            }
                                                          >
                                                            -
                                                          </button>
                                                          <span className="quantity m-2">
                                                            {cart.length > 0
                                                              ? cart[
                                                                  getObjectById(
                                                                    cart,
                                                                    wdata._id,
                                                                    "window"
                                                                  )
                                                                ].quantity
                                                              : 0}
                                                          </span>
                                                          <button
                                                            type="button"
                                                            className="btn btn-primary btn-sm m-1"
                                                            onClick={() =>
                                                              handleCountUp(
                                                                windowAC,
                                                                setWindowAC,
                                                                index,
                                                                wdata.quantity,
                                                                "add",
                                                                "window"
                                                              )
                                                            }
                                                          >
                                                            +
                                                          </button>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <hr className="mt-3" />
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                      </TabPane>
                                    </TabContent>
                                    <div className="text-right mt-4">
                                      {totalCartPrice === 0 ? (
                                        ""
                                      ) : (
                                        <>
                                          <div style={{ float: "right" }}>
                                            <button
                                              className="btn btn-primary"
                                              onClick={() => {
                                                databyid();
                                              }}
                                            >
                                              <i className="fa fa-shopping-cart mr-2" />
                                              {""} Checkout Total: ₹{" "}
                                              {totalCartPrice}
                                            </button>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <ToastContainer />
            <Footer
              Services={Services}
              Products={Products1}
              Contact={Contact}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default AboutUs;
