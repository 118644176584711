import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { NavLink } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Card from "react-bootstrap/Card";
import Carousel from "react-bootstrap/Carousel";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [isLoading, setIsLoading] = useState(true);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleShows = () => {
    setShow(true);
  };

  useEffect(() => {
    datas();
    amcService();
    handleShows();
  }, []);

  const [Sliders, setSliders] = useState([]);

  const [Sliders1, setSliders1] = useState([]);

  const [Aboutus, setAboutus] = useState([]);

  const [Aboutus1, setAboutus1] = useState([]);

  const [Services, setServices] = useState([]);

  const [Products, setProducts] = useState([]);

  const [Products1, setProducts1] = useState([]);

  const [Contact, setContact] = useState([]);

  const [clients, setclients] = useState([]);

  const [HomeContant, setHomeContant] = useState([]);

  const [ProjectCat, setProjectCat] = useState([]);

  const [Projects, setProjects] = useState([]);

  const [Projects1, setProjects1] = useState([]);

  const [Projects2, setProjects2] = useState([]);

  const api = "https://api.ssairconditioners.com/";

  const datas = () => {
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setSliders(res.data.homeSlider);
            setSliders1(res.data.homeSlider[0]);
            setAboutus(res.data.aboutus[0]);
            setclients(res.data.financing);
            setServices(res.data.services);
            setProducts(res.data.product);
            setProjects(res.data.projectcategorys);
            setProducts1(res.data.productcategorys);
            setHomeContant(res.data.homecontents[0]);
            setContact(res.data.contactus[0]);
            setProjects1(res.data.projects);
            setProjects2(res.data.ongoingprojects);
            setProjectCat(res.data.projectservices);
            setAboutus1(res.data.aboutus[0].whyWeChooseUs);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  const [Service1, setService1] = useState([]);

  const amcService = () => {
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/getamcbyid",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setService1(res.data.amcService);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
          }
        }
      );
  };

  const databyid = (data) => {
    sessionStorage.setItem("Ids", data._id);
    window.location.href = "/service";
  };

  const databyids = (data) => {
    sessionStorage.setItem("splitIds", data._id);
    window.location.href = "/viewproduct";
  };

  const settingss = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  const [form, setform] = useState([]);

  const formsubmit = (e) => {
    e.preventDefault();
    Add();
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const Add = () => {
    const dataArray = {
      name: form.name,
      email: form.email,
      phoneNumber: form.phoneNumber,
      subject: form.subject,
      description: form.description,
    };

    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/enquiry/addenquiry",
        dataArray
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            setform({
              name: "",
              email: "",
              phoneNumber: "",
              subject: "",
              description: "",
            });
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const settings1 = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4500,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    width: 500,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const databyidamc = (data) => {
    sessionStorage.setItem("Catid", data._id);
    navigate("/acmantanceservice");
  };

  const settingss1 = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: true,
    afterChange: function (index) {},
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {isLoading == true ? (
        <>
          <div class="centers">
            <span class="loader"></span>
          </div>
        </>
      ) : (
        <>
          <div className="wrapper">
            <Header
              Services={Services}
              Projects={Projects}
              Products={Products1}
              Contact={Contact}
            />
            <div id="ltd">
              <div className="tm-heroslider" id="fld">
                <div className="tm-heroslider-images ">
                  <div className="slick-list draggable">
                    <Slider {...settings}>
                      {Sliders.map((data, i) => {
                        return (
                          <>
                            <div key={i}>
                              <div
                                className="tm-heroslider-image slick-slide slick-current slick-active"
                                data-bgimage={api + data.image}
                                style={{
                                  backgroundImage: `url(${api + data.image})`,
                                  height: "500px",
                                  width: "100%",
                                }}
                              />
                            </div>
                          </>
                        );
                      })}
                    </Slider>
                  </div>
                </div>

                <div className="tm-heroslider-content">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-7 col-md-10" id="upd">
                        <h1 id="titling">{Sliders1.title}</h1>
                        <p className="bannertet">{Sliders1.description}</p>
                        <button
                          type="button"
                          style={{
                            width: "25%",
                            marginTop: 6,
                            backgroundColor: "#2858A9",
                            border: 0,
                            color: "white",
                            height: "35px",
                          }}
                          onClick={handleShow}
                        >
                          <span> Contact Us</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="ltds">
              <div id="fld">
                <Carousel fade data-bs-theme="dark">
                  {Sliders.map((data, i) => (
                    <Carousel.Item key={i}>
                      <img
                        className="d-block w-100"
                        src={api + data.image}
                        alt={`Slide ${i + 1}`}
                        height="250px"
                      />
                      <Carousel.Caption>
                        <div
                          style={{
                            width: "70%",
                            paddingBottom: "20px",
                            background: "rgb(250,250,250,0.4)",
                            borderRadius: "10px",
                          }}
                        >
                          <h5 style={{ fontSize: "20px", color: "black" }}>
                            {Sliders1.title.slice(0, 60)}...
                          </h5>
                          <p style={{ fontSize: "10px", color: "black" }}>
                            {Sliders1.description.slice(0, 100)}...
                          </p>
                        </div>
                      </Carousel.Caption>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            </div>

            <main className="page-content">
              <div class="container py-xl-4 py-lg-3 py-md-2 py-sm-3 py-2">
                <div class="row" id="ltd">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center px-lg-0 px-md-2 px-sm-2 px-2">
                    <Slider {...settingss}>
                      {ProjectCat.map((data, i) => {
                        return (
                          <>
                            <div key={i} style={{ padding: "20px" }}>
                              <img
                                src={api + data.image}
                                data-src={api + data.image}
                                alt="Residential Icon"
                                style={{
                                  height: "100px",
                                  width: "80%",
                                  borderRadius: "10px",
                                }}
                                className="mx-auto"
                                data-was-processed="true"
                                id="zoom"
                              />
                            </div>
                            <div class="home-caption text-center ">
                              <p
                                class="home-text pt-2 "
                                id="titling"
                                style={{ color: "black" }}
                              >
                                {data.name}
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </Slider>
                  </div>
                </div>

                <div class="row" id="ltds">
                  {ProjectCat.map((data, i) => {
                    return (
                      <>
                        <div class="col-3  text-center px-lg-0 px-md-2 px-sm-2 px-2">
                          <div key={i} style={{ padding: "10px" }}>
                            <img
                              src={api + data.image}
                              data-src={api + data.image}
                              alt="Residential Icon"
                              style={{
                                height: "55px",
                                width: "100%",
                                borderRadius: "10px",
                              }}
                              data-was-processed="true"
                              id="zoom"
                            />
                          </div>
                          <div class="home-caption text-center">
                            <h6
                              class="home-text pt-2"
                              style={{
                                color: "black",
                                fontSize: "8px",
                              }}
                            >
                              {data.name.slice(0, 30)}...
                            </h6>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>

              <div
                id="tm-about-area"
                className="tm-about-area tm-section "
                style={{
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  background: "#F7F7F7",
                }}
              >
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-12 text-center  mt-1">
                      <div className="row justify-content-center">
                        <div className="col-xl-12 col-lg-12">
                          <div className="tm-sectiontitle text-center">
                            <h3 className="text-primary" id="titling">
                              WELCOME TO
                            </h3>
                            <h4 style={{ fontSize: "25px" }} id="titling">
                              SS AIR CONDITIONING SOLUTIONS
                            </h4>
                            <span className="tm-sectiontitle-divider">
                              <img
                                alt="divider icon"
                                src="assets/images/ios4.png"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="tm-about-content">
                        <h5>{Aboutus.title}</h5>
                        <p>{Aboutus.description}</p>

                        <div className="tm-about-buttons tm-buttongroup">
                          <NavLink to="/aboutUs">
                            <button
                              type="button"
                              style={{
                                marginLeft: "20px",
                                marginTop: 6,
                                backgroundColor: "#2858A9",
                                border: 0,
                                color: "white",
                                height: "35px",
                              }}
                            >
                              <span> Read More</span>
                            </button>
                          </NavLink>
                          <a href="tel:+18009156270" className="tm-callbutton">
                            <img
                              src="assets/images/icons/icon-callbutton-phone.png"
                              alt="call icon"
                            />
                            <h5>24/7 Customer Care</h5>
                            <h4>+91 {Contact.contactNumber}</h4>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 pt-4">
                      <div>
                        <img
                          src={api + Aboutus.image}
                          alt="image"
                          style={{
                            borderRadius: "40px",
                            background: "white",
                            width: "100%",
                            height: "350px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="tm-blogs-area"
                class="tm-blog-area tm-section pt-3 bg-grey mb-4"
              >
                <div class="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-8">
                      <div className="tm-sectiontitle text-center">
                        <h3 className="pt-2 text-primary" id="titling">
                          SERVICES
                        </h3>
                        <span className="tm-sectiontitle-divider">
                          <img
                            alt="divider icon"
                            src="assets/images/ios4.png"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row blog-slider-active tm-slider-dots-2">
                    {Service1.slice(0, 1).map((data, i) => {
                      return (
                        <>
                          <div
                            class="col-lg-4 col-md-6 col-12 mt-3"
                            key={i}
                            style={{ borderRadius: "5px" }}
                          >
                            <Card style={{ width: "100%" }} id="opsds">
                              <Card.Img
                                variant="top"
                                src={api + data.image}
                                height="220px"
                                onClick={() => {
                                  databyidamc(data);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                              <Card.Body>
                                <Card.Title
                                  style={{ color: "#2858A9" }}
                                  className="text-center"
                                >
                                  {data.title}
                                  <hr></hr>
                                </Card.Title>
                                <Card.Text className="text-center ">
                                  <p>{data.description.slice(0, 200)}...</p>
                                </Card.Text>
                                <hr></hr>
                                <div className="text-center ">
                                  <button
                                    type="button"
                                    style={{
                                      width: "40%",
                                      marginTop: 6,
                                      backgroundColor: "#2858A9",
                                      border: 0,
                                      color: "white",
                                      height: "30px",
                                    }}
                                    onClick={() => {
                                      databyidamc(data);
                                    }}
                                  >
                                    <span>Book Now</span>
                                  </button>
                                </div>
                              </Card.Body>
                            </Card>
                          </div>
                        </>
                      );
                    })}
                    {Services.map((data, i) => {
                      return (
                        <>
                          <div
                            class="col-lg-4 col-md-6 col-12 mt-3"
                            key={i}
                            style={{ borderRadius: "5px" }}
                          >
                            <Card style={{ width: "100%" }} id="opsds">
                              <Card.Img
                                variant="top"
                                src={api + data.video}
                                height="220px"
                                onClick={() => {
                                  databyid(data);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                              <Card.Body>
                                <Card.Title
                                  style={{ color: "#2858A9" }}
                                  className="text-center"
                                >
                                  {data.name}
                                  <hr></hr>
                                </Card.Title>
                                <Card.Text className="text-center ">
                                  <p>{data.description.slice(0, 200)}...</p>
                                </Card.Text>
                                <hr></hr>
                                <div className="text-center ">
                                  <button
                                    type="button"
                                    style={{
                                      width: "40%",
                                      marginTop: 6,
                                      backgroundColor: "#2858A9",
                                      border: 0,
                                      color: "white",
                                      height: "30px",
                                    }}
                                    onClick={() => {
                                      databyid(data);
                                    }}
                                  >
                                    <span>Book Now</span>
                                  </button>
                                </div>
                              </Card.Body>
                            </Card>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div
                id="tm-about-area"
                className="tm-about-area tm-section pb-3"
                style={{ paddingTop: "20px", background: "#F7F7F7" }}
              >
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-8">
                      <div className="tm-sectiontitle text-center">
                        <h3 className="pt-2 text-primary" id="titling">
                          WHY WE CHOOSE US
                        </h3>
                        <span className="tm-sectiontitle-divider">
                          <img
                            alt="divider icon"
                            src="assets/images/ios4.png"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center mb-3 ">
                    <div class="col-lg-6">
                      <div class="tm-whyus-image">
                        <img src={api + Aboutus.image1} height="550px" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="tm-whyus-content">
                        <div class="tm-whyus-blocks">
                          {Aboutus1.map((data, i) => (
                            <div class="tm-whyus-block">
                              <h5 style={{ marginBottom: "10px" }}>
                                <span>{i + 1}</span> {data.title}
                              </h5>
                              <p style={{ paddingLeft: "68px" }}>
                                {data.description}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="tm-services-area"
                className="tm-services-area tm-section bg-white mt-4"
              >
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-8">
                      <div className="tm-sectiontitle text-center">
                        <h3 className="pt-2 text-primary" id="titling">
                          PRODUCTS
                        </h3>
                        <span className="tm-sectiontitle-divider">
                          <img
                            alt="divider icon"
                            src="assets/images/ios4.png"
                          />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <Slider {...settingss1}>
                      {Products.map((data, i) => {
                        return (
                          <>
                            <div style={{ margin: "4px" }} key={i} id="opsd">
                              <div
                                className="product-item-info text-center"
                                data-container="product-grid"
                              >
                                <a>
                                  <span className="product-image-container">
                                    <span
                                      className="product-image-wrapper"
                                      style={{ paddingBottom: "100%" }}
                                    >
                                      <img
                                        className="product-image-photo"
                                        src={api + data.image}
                                        height="250px"
                                        style={{ width: "100%" }}
                                      />
                                    </span>
                                  </span>
                                </a>
                                <div className="product details product-item-details">
                                  <strong className="hjj">
                                    <a className="hjj">{data.name}</a>
                                  </strong>
                                  <h6>
                                    <div className="tm-ratingbox">
                                      <span className="is-active">
                                        <i className="ion-ios-star" />
                                      </span>
                                      <span className="is-active">
                                        <i className="ion-ios-star" />
                                      </span>
                                      <span className="is-active">
                                        <i className="ion-ios-star" />
                                      </span>
                                      <span className="is-active">
                                        <i className="ion-ios-star-half" />
                                      </span>
                                      <span>
                                        <i className="ion-ios-star" />
                                      </span>
                                    </div>
                                  </h6>
                                  <h6>{data.price}/-</h6>
                                  <div
                                    className="price-box price-final_price text-center"
                                    data-role="priceBox"
                                    data-product-id={772}
                                    data-price-box="product-id-772"
                                  >
                                    <span className="special-price">
                                      <span className="price-container price-final_price tax weee">
                                        <span
                                          id="product-price-772"
                                          data-price-type="finalPrice"
                                          className="price-wrapper "
                                        >
                                          <span className="price">
                                            {data.description.slice(0, 100)}
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                                <div
                                  style={{ width: "100%" }}
                                  className="mb-2 text-center"
                                >
                                  <button
                                    type="button"
                                    style={{
                                      width: "40%",
                                      marginTop: 6,
                                      backgroundColor: "#2858A9",
                                      border: 0,
                                      color: "white",
                                      height: "30px",
                                    }}
                                    onClick={() => {
                                      databyids(data);
                                    }}
                                  >
                                    <span>View More</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              </div>

              <div
                id="tm-services-area"
                className="tm-services-area tm-section pb-5 pt-4  mt-4 "
                style={{ background: "#F7F7F7" }}
              >
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-8">
                      <div className="tm-sectiontitle text-center">
                        <h3 className="text-primary" id="titling">
                          RUNNING PROJECTS
                        </h3>
                        <span className="tm-sectiontitle-divider">
                          <img
                            alt="divider icon"
                            src="assets/images/ios4.png"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-3 col-12 mb-2">
                        {Projects2.slice(0, 1).map((data, i) => {
                          return (
                            <>
                              <img
                                key={i}
                                src={api + data.image}
                                alt="oyoxo product"
                                height="380px"
                                width="100%"
                              />
                            </>
                          );
                        })}
                      </div>
                      <div class="col-lg-9 col-12">
                        <div class="tm-shop-products">
                          <div class="row mt-30-reverse">
                            {Projects2.slice(0, 8).map((data, i) => {
                              return (
                                <>
                                  <div
                                    class="col-lg-3 col-md-6 col-sm-6 col-12 mt-30 pt-3"
                                    key={i}
                                  >
                                    <div class="tm-product">
                                      <div class="tm-product-top">
                                        <img
                                          src={api + data.image}
                                          alt="oyoxo product"
                                          height="150px"
                                          width="100%"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fulid branchShowcase pt-2 pb-2 ">
                <div
                  id="tm-financing-area"
                  className="tm-financing-area tm-section"
                >
                  <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-12">
                      <div className="tm-sectiontitle text-center">
                        <h3 className="text-primary mt-3" id="titling">
                          {HomeContant.financingTitle}
                        </h3>
                        <span className="tm-sectiontitle-divider">
                          <img
                            alt="divider icon"
                            src="assets/images/ios4.png"
                          />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div
                      id="tm-pricing-area"
                      className="tm-pricing-plan tm-section "
                    >
                      <div className="container">
                        <div className="row mt-30-reverse justify-content-center">
                          {clients.slice(0, 3).map((data, i) => {
                            return (
                              <>
                                <div
                                  className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30"
                                  key={i}
                                >
                                  <div
                                    className="tm-pricebox text-center  is-active"
                                    style={{
                                      background: "#F7F7F7",
                                      height: "500px",
                                    }}
                                  >
                                    <div className="tm-pricebox-price">
                                      <img
                                        src={api + data.image}
                                        style={{
                                          height: "40px",
                                          margin: "auto",
                                          width: "90%",
                                        }}
                                      />
                                    </div>
                                    <h5
                                      className="tm-pricebox-title pt-3 text-primary"
                                      style={{ height: "60px" }}
                                    >
                                      {data.title.slice(0, 40)}
                                    </h5>
                                    <ul>
                                      <li style={{ height: "80px" }}>
                                        {data.description.slice(0, 100)}
                                      </li>
                                      <li className="pt-4 text-danger">
                                        <span className="text-primary">
                                          Expire Date :
                                        </span>{" "}
                                        {data.expiredDate}
                                      </li>
                                    </ul>
                                    <hr></hr>
                                    <button
                                      type="button"
                                      style={{
                                        width: "40%",
                                        marginTop: 6,
                                        backgroundColor: "#2858A9",
                                        border: 0,
                                        color: "white",
                                        height: "30px",
                                      }}
                                    >
                                      <NavLink to="/acmantance">
                                        <span
                                          style={{
                                            color: "white",
                                          }}
                                        >
                                          Book Now
                                        </span>
                                      </NavLink>
                                    </button>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="tm-services-area"
                className="tm-services-area tm-section pt-4"
              >
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-8">
                      <div className="tm-sectiontitle text-center">
                        <h3 className="text-primary" id="titling">
                          Completed PROJECTS
                        </h3>
                        <span className="tm-sectiontitle-divider">
                          <img
                            alt="divider icon"
                            src="assets/images/ios4.png"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-3 col-12 mb-2">
                        {Projects1.slice(0, 1).map((data, i) => {
                          return (
                            <>
                              <img
                                key={i}
                                src={api + data.image}
                                alt="oyoxo product"
                                height="380px"
                                width="100%"
                              />
                            </>
                          );
                        })}
                      </div>
                      <div class="col-lg-9 col-12">
                        <div class="tm-shop-products">
                          <div class="row mt-30-reverse">
                            {Projects1.slice(0, 8).map((data, i) => {
                              return (
                                <>
                                  <div
                                    class="col-lg-3 col-md-6 col-sm-6 col-12 mt-30 pt-3"
                                    key={i}
                                  >
                                    <div class="tm-product">
                                      <div class="tm-product-top">
                                        <img
                                          src={api + data.image}
                                          alt="oyoxo product"
                                          height="150px"
                                          width="100%"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pb-2 mt-2" id="rtx">
                  <div className="container-fluid">
                    <div className="row justify-content-center">
                      <div className="col-xl-12 col-lg-12">
                        <div className="tm-sectiontitle text-center">
                          <h3 className="text-white pt-4" id="titling">
                            {HomeContant.getintouchTitle}
                          </h3>
                          <span className="tm-sectiontitle-divider">
                            <img
                              alt="divider icon"
                              src="assets/images/ios4.png"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center pt-2">
                      <div className="col-lg-6">
                        <div id="google-map" className="google-map pb-3">
                          <iframe
                            src={Contact.maps}
                            frameBorder={0}
                            scrolling="no"
                            style={{
                              height: "100%",
                              width: "100%",
                              borderRadius: "20px",
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 pb-4 ">
                        <div style={{ border: "1px solid white" }}>
                          <h4
                            className="text-center text-white  pt-3"
                            style={{
                              borderBottom: "1px solid white",
                              borderRadius: "10px",
                            }}
                          >
                            {HomeContant.getintouchTitle}
                          </h4>

                          <div className="m-3">
                            <form
                              onSubmit={(e) => {
                                formsubmit(e);
                              }}
                            >
                              <div className="row mt-3">
                                <div className="col-md-6 col-12 ">
                                  <div className="mb-3">
                                    <label className="text-white">
                                      Full Name
                                    </label>{" "}
                                    <span className="text-danger">*</span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Your Full Name"
                                      name="name"
                                      required
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      value={form.name}
                                    />
                                  </div>{" "}
                                </div>{" "}
                                <div className="col-md-6 col-12">
                                  <div className="mb-3">
                                    <label className="text-white">
                                      Email Id
                                    </label>{" "}
                                    <span className="text-danger">*</span>
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Enter Your Email Id"
                                      name="email"
                                      required
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      value={form.email}
                                    />
                                  </div>
                                </div>{" "}
                                <div className="col-md-6 col-12">
                                  <div className="mb-3">
                                    <label className="text-white">
                                      Phone Number
                                    </label>
                                    <span className="text-danger">*</span>
                                    <input
                                      type="text"
                                      placeholder="Enter Your Phone Number"
                                      name="phoneNumber"
                                      className="form-control"
                                      required
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      maxlength="10"
                                      minLength="10"
                                      pattern="[0-9]{10}"
                                      value={form.phoneNumber}
                                      onKeyPress={(e) => {
                                        // Allow only numeric input
                                        const charCode = e.which
                                          ? e.which
                                          : e.keyCode;
                                        if (charCode < 48 || charCode > 57) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                  </div>
                                </div>{" "}
                                <div className="col-md-6 col-12">
                                  <div className="mb-3">
                                    <label className="text-white">
                                      Service{" "}
                                    </label>
                                    <span className="text-danger">*</span>
                                    <select
                                      value={form.subject}
                                      name="subject"
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      className="form-select"
                                    >
                                      <option value="">Select</option>
                                      {Services.map((data, key) => {
                                        return (
                                          <option key={key} value={data.name}>
                                            {data.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </div>{" "}
                                <div className="col-12">
                                  <div className="mb-3">
                                    <label className="text-white">
                                      Description
                                    </label>
                                    <textarea
                                      cols={30}
                                      rows={3}
                                      placeholder="Enter Description"
                                      className="form-control"
                                      name="description"
                                      required
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      value={form.description}
                                    />
                                  </div>
                                  <div style={{ float: "right" }}>
                                    <button
                                      type="submit"
                                      style={{
                                        marginTop: 6,
                                        backgroundColor: "#2858A9",
                                        border: 0,
                                        color: "white",
                                        height: "35px",
                                      }}
                                    >
                                      Submit Now
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="form-messages" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <Modal
              size="lg"
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              className="mt-5"
            >
              <div className="row login-page">
                <div className="col-md-5 col-5">
                  <div className="login-side-des">
                    <div className="login-side-block">
                      <div className="login-reviews">
                        <div className="review-details-content">
                          <div className="review_details" id="review_details-2">
                            <Slider {...settings1}>
                              {clients.map((data, i) => {
                                return (
                                  <>
                                    <div className="item" key={i}>
                                      <div className="pt-5 text-center">
                                        <img
                                          src={api + data.image}
                                          style={{
                                            height: "40px",
                                            margin: "auto",
                                            width: "50%",
                                          }}
                                        />
                                      </div>{" "}
                                      <h6 className="pt-5 text-center">
                                        {data.title}
                                      </h6>
                                      <p className="text-center">
                                        {data.description}
                                      </p>
                                    </div>
                                  </>
                                );
                              })}
                            </Slider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7 col-7">
                  <div>
                    <form
                      onSubmit={(e) => {
                        formsubmit(e);
                      }}
                    >
                      <div className="row m-2">
                        <div className="col-md-6 col-12">
                          <div className="mb-2">
                            <label className="text-dark">Full Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Your Full Name"
                              name="name"
                              required
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={form.name}
                            />
                          </div>{" "}
                        </div>{" "}
                        <div className="col-md-6 col-12">
                          <div className="mb-2">
                            <label className="text-dark">Email Id</label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Enter Your Email Id"
                              name="email"
                              required
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={form.email}
                            />
                          </div>
                        </div>{" "}
                        <div className="col-md-6 col-12">
                          <div className="mb-2">
                            <label className="text-dark">Phone Number</label>
                            <input
                              type="text"
                              placeholder="Enter Your Phone Number"
                              name="phoneNumber"
                              className="form-control"
                              required
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={form.phoneNumber}
                              maxlength="10"
                              minLength="10"
                              pattern="[0-9]{10}"
                              onKeyPress={(e) => {
                                // Allow only numeric input
                                const charCode = e.which ? e.which : e.keyCode;
                                if (charCode < 48 || charCode > 57) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>{" "}
                        <div className="col-md-6 col-12 ">
                          <div className="mb-2">
                            <label className="text-dark">Service </label>
                            <select
                              value={form.subject}
                              name="subject"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              className="form-select"
                            >
                              <option value="">Select</option>
                              {Services.map((data, key) => {
                                return (
                                  <option key={key} value={data.name}>
                                    {data.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>{" "}
                        <div className="col-12 ">
                          <div className="mb-3">
                            <label className="text-dark">Description</label>
                            <textarea
                              cols={20}
                              rows={3}
                              placeholder="Enter Description"
                              className="form-control"
                              name="description"
                              required
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={form.description}
                            />
                          </div>{" "}
                        </div>
                        <div className="col-12 ">
                          <div style={{ float: "right" }}>
                            <button
                              type="button"
                              style={{
                                marginTop: 6,
                                backgroundColor: "#a9282e",
                                border: 0,
                                color: "white",
                                height: "35px",
                                marginRight: "2px",
                              }}
                              onClick={() => {
                                handleClose();
                              }}
                            >
                              Close
                            </button>

                            <button
                              type="submit"
                              style={{
                                marginTop: 6,
                                backgroundColor: "#2858A9",
                                border: 0,
                                color: "white",
                                height: "35px",
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Modal>

            <ToastContainer />
            <Footer
              Services={Services}
              Products={Products1}
              Contact={Contact}
            />
          </div>
        </>
      )}
    </>
  );
}

export default Home;
