import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Card from "react-bootstrap/Card";

function AboutUs() {
  useEffect(() => {
    datas();
  }, []);

  const [Service, setService] = useState([]);

  const [Service1, setService1] = useState([]);

  const getid = sessionStorage.getItem("Ids");

  const api = "https://api.ssairconditioners.com/";

  const datas = () => {
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/getservicebyid",
        { id: getid },
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setService(res.data.service);
            setService1(res.data.serviceprocess);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
          }
        }
      );
  };

  const trimmedBannerImage = Service.bannerImage
    ? Service.bannerImage.trim()
    : "";

  const imageUrl = trimmedBannerImage ? api + trimmedBannerImage : "";

  const [Contact, setContact] = useState([]);
  const [Projects, setProjects] = useState([]);
  const [Products1, setProducts1] = useState([]);
  const [Services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    datas2();
  }, []);

  const datas2 = () => {
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setContact(res.data.contactus[0]);
            setProducts1(res.data.productcategorys);
            setProjects(res.data.projectcategorys);
            setServices(res.data.services);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  const [form, setform] = useState([]);

  const formsubmit = (e) => {
    e.preventDefault();
    Add();
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const Add = () => {
    const dataArray = {
      name: form.name,
      email: form.email,
      phoneNumber: form.phoneNumber,
      date: form.date,
      description: form.description,
      address: form.address,
      serviceId: Service._id,
    };

    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/serviceenquiry/addserviceenquiry",
        dataArray
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            setform({
              name: "",
              email: "",
              phoneNumber: "",
              date: "",
              description: "",
              address: "",
            });
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
            <span class="loader"></span>
          </div>
        </>
      ) : (
        <>
          <div className="wrapper">
            <Header
              Services={Services}
              Products={Products1}
              Projects={Projects}
              Contact={Contact}
            />

            <main className="page-content" id="fld">
              <div
                id="tm-about-area"
                className="tm-about-area tm-section bg-white"
              >
                <div
                  className="page-title-area pt-210 pb-180"
                  style={{ backgroundImage: `url(${imageUrl})` }}
                >
                  <div className="container">
                    <div className="page-title-icon">
                      <i className="flaticon-air-conditioner hero__icon hero__icon1" />
                      <i className="flaticon-heating hero__icon hero__icon2" />
                      <i className="flaticon-vacuum-cleaner hero__icon hero__icon3" />
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="page-title text-center">
                          <h2 className="breadcrumb-title">{Service.name}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container mt-5">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <img
                        src={api + Service.video}
                        style={{ height: "300px", width: "100%" }}
                      ></img>
                    </div>
                    <div className="col-lg-6">
                      <div className="tm-about-content">
                        <h2 className="text-primary">{Service.name}</h2>
                        <p>{Service.description}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="container mt-5 mb-5">
                  <div class="row ">
                    <div class="col-xl-12 ">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: Service.largeDescription,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="container">
                  {Service1.length == "0" ? (
                    <></>
                  ) : (
                    <>
                      <h2 className="text-center pb-2 text-primary">
                        Our Services
                      </h2>
                    </>
                  )}
                  <div class="row ">
                    {Service1.map((data, i) => {
                      return (
                        <>
                          <div
                            class="col-lg-4 col-md-6 col-12 mt-3"
                            key={i}
                            style={{ borderRadius: "5px" }}
                          >
                            <Card style={{ width: "100%" }} id="opsds">
                              <Card.Img
                                variant="top"
                                src={api + data.image}
                                height="220px"
                                style={{ cursor: "pointer" }}
                              />
                              <Card.Body>
                                <Card.Title className="text-center">
                                  <b style={{ color: "#2858A9" }}>
                                    {data.name}
                                  </b>
                                </Card.Title>
                                <hr></hr>
                                <Card.Text className="text-center ">
                                  {data.description.slice(0, 300)}...
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>

                <div className="pb-2 mt-3" id="rtx">
                  <div className="container">
                    <div className="row justify-content-center pt-5">
                      <div className="col-lg-12 pb-4">
                        <div style={{ border: "1px solid white" }}>
                          <h4
                            className="text-center text-white  pt-3"
                            style={{
                              borderBottom: "1px solid white",
                              borderRadius: "10px",
                            }}
                          >
                          Contact Now
                          </h4>

                          <div className="m-3">
                            <form
                              onSubmit={(e) => {
                                formsubmit(e);
                              }}
                            >
                              <div className="row justify-content-center ">
                                <div className="col-lg-6 col-md-6 col-12">
                                  <div>
                                    <label className="text-white">Name</label>
                                    <input
                                      type="text"
                                      placeholder="Enter Your Name"
                                      name="name"
                                      className="form-control"
                                      required
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      value={form.name}
                                    />
                                  </div>

                                  <div>
                                    <label className="text-white">Email</label>
                                    <input
                                      type="email"
                                      placeholder="Enter Your Email Id"
                                      name="email"
                                      className="form-control"
                                      required
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      value={form.email}
                                    />
                                  </div>
                                  <div>
                                    <label className="text-white">
                                      Phone Number
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Your Phone Number"
                                      name="phoneNumber"
                                      className="form-control"
                                      required
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      maxlength="10"
                                      minLength="10"
                                      pattern="[0-9]{10}"
                                      value={form.phoneNumber}
                                      onKeyPress={(e) => {
                                        // Allow only numeric input
                                        const charCode = e.which
                                          ? e.which
                                          : e.keyCode;
                                        if (charCode < 48 || charCode > 57) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <label className="text-white">Date</label>
                                    <input
                                      type="date"
                                      placeholder="Enter Date"
                                      name="date"
                                      className="form-control"
                                      required
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      value={form.date}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                  <div >
                                    <label className="text-white">
                                      Address
                                    </label>
                                    <textarea
                                      placeholder="Enter Your Address"
                                      className="form-control"
                                      name="address"
                                      required
                                      rows={3}
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      value={form.address}
                                    />
                                  </div>

                                  <div>
                                    <label className="text-white">
                                      Description
                                    </label>
                                    <textarea
                                      placeholder="Enter Description"
                                      name="description"
                                      className="form-control"
                                      required
                                      rows={3}
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      value={form.description}
                                    />
                                  </div>
                                  <div style={{float:"right"}} className="mt-4">
                                    <button
                                      type="submit"
                                      style={{
                                       
                                        backgroundColor: "#2858A9",
                                        border: 0,
                                        color: "white",
                                        height: "35px",
                                      }}
                                    >
                                      Book Now
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <p className="form-messages" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </main>
            <ToastContainer />
            <Footer
              Services={Services}
              Products={Products1}
              Contact={Contact}
            />
          </div>{" "}
        </>
      )}
    </div>
  );
}

export default AboutUs;
