import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";
import Card from "react-bootstrap/Card";

function AboutUs() {
  const [isLoading, setIsLoading] = useState(true);

  var settings4 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    datas();
  }, []);

  const api = "https://api.ssairconditioners.com/";

  const [Aboutus, setAboutus] = useState([]);

  const [Testimonials, setTestimonials] = useState([]);

  const [Professionals, setProfessionals] = useState([]);

  const [HomeContant, setHomeContant] = useState([]);

  const [Contact, setContact] = useState([]);
  const [Projects, setProjects] = useState([]);
  const [Products1, setProducts1] = useState([]);
  const [Services, setServices] = useState([]);

  const [clients, setclients] = useState([]);

  const datas = () => {
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setAboutus(res.data.aboutus[0]);
            setclients(res.data.brandClients);
            setTestimonials(res.data.testimonials);
            setProfessionals(res.data.professionals);
            setHomeContant(res.data.homecontents[0]);
            setContact(res.data.contactus[0]);
            setProducts1(res.data.productcategorys);
            setProjects(res.data.projectcategorys);
            setServices(res.data.services);

            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  const settingss1 = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: true,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
            <span class="loader"></span>
          </div>
        </>
      ) : (
        <>
          <div className="wrapper">
            <Header
              Services={Services}
              Products={Products1}
              Projects={Projects}
              Contact={Contact}
            />

            <main className="page-content" id="fld">
              <div
                className="page-title-area pt-210 pb-180"
                style={{
                  backgroundImage: `url(${"https://img.freepik.com/free-photo/3d-rendering-ventilation-system_23-2149281318.jpg?t=st=1712848688~exp=1712852288~hmac=eca6c674fc37ba4eeb398f075383057c51d70cc35c6a1f7039116b6e6a7fdcc4&w=826"})`,
                }}
              >
                <div className="container">
                  <div className="page-title-icon">
                    <i className="flaticon-air-conditioner hero__icon hero__icon1" />
                    <i className="flaticon-heating hero__icon hero__icon2" />
                    <i className="flaticon-vacuum-cleaner hero__icon hero__icon3" />
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="page-title text-center">
                        <h2 className="breadcrumb-title">About Us</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="tm-about-area"
                className="tm-about-area tm-section bg-white pt-5"
              >
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div>
                        <img
                          src={api + Aboutus.image}
                          style={{ borderRadius: "40px", background: "white" }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="tm-about-content">
                        <h4 className="text-primary">About Us</h4>
                        <h6 style={{ color: "black" }}>{Aboutus.title}</h6>
                        <p>{Aboutus.description}</p>

                        <div className="tm-about-buttons tm-buttongroup">
                          <NavLink to="/contact" className="tm-button">
                            Contact Us
                          </NavLink>

                          <a href="tel:+18009156270" className="tm-callbutton">
                            <img
                              src="assets/images/icons/icon-callbutton-phone.png"
                              alt="call icon"
                            />
                            <h5>24/7 Customer Care</h5>
                            <h4>+91 {Contact.contactNumber}</h4>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="tm-team-area"
                className="tm-member-area tm-section bg-grey mt-5 pb-5"
                style={{ background: "#F7F7F7" }}
              >
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-8">
                      <div className="tm-sectiontitle text-center mt-5">
                        <h4 className="text-primary">
                          {" "}
                          {HomeContant.professionalTitle}
                        </h4>
                        <span className="tm-sectiontitle-divider">
                          <img
                            alt="divider icon"
                            src="assets/images/ios4.png"
                          />
                        </span>
                        <p>{HomeContant.professionalDescription}</p>
                      </div>
                    </div>
                  </div>

                  <div class="row blog-slider-active tm-slider-dots-2">
                    {Professionals.map((data, i) => {
                      return (
                        <>
                          <div
                            class="col-lg-4 col-md-6 col-12 mt-3"
                            key={i}
                            style={{ borderRadius: "5px" }}
                          >
                            <Card style={{ width: "100%" }} id="opsds">
                              <Card.Img
                                variant="top"
                                src={api + data.image}
                                height="250px"
                                style={{ cursor: "pointer" }}
                              />
                              <Card.Body>
                                <Card.Title
                                  style={{ color: "#2858A9" }}
                                  className="text-center "
                                >
                                  {data.name}
                                </Card.Title>
                                <hr></hr>
                                <Card.Text className="text-center ">
                                  {data.designation}
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="container brands-section">
                <div className="row py-xl-5 py-lg-4 py-md-4 py-sm-4 py-2 px-0">
                  <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 px-sm-1 px-2 py-1">
                    <div className="pt-2">
                      <h4
                        className="text-left font-weight-bold text-dark"
                        style={{
                          fontFamily: "Poppins",
                          color: "#888",
                          letterSpacing: "0.3px",
                        }}
                      >
                        We are{" "}
                        <span
                          className="brands-title-end"
                          style={{ color: "#2858A9" }}
                        >
                          Associated
                        </span>
                      </h4>
                    </div>
                  </div>
                  <div
                    className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 px-sm-1 px-2 py-1 "
                    style={{ background: "white" }}
                  >
                    <Slider {...settingss1}>
                      {clients.map((data, i) => {
                        return (
                          <>
                            <div key={i}>
                              <div>
                                <img
                                  src={api + data.image}
                                  alt="Brand Image"
                                  style={{
                                    height: "50px",
                                    width: "70%",
                                    margin: "auto",
                                    padding: "2px",
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              </div>
            </main>
            <Footer
              Services={Services}
              Products={Products1}
              Contact={Contact}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default AboutUs;
