import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Collapse } from "reactstrap";

function CheckOut(args) {
  const [Contact, setContact] = useState([]);
  const [Projects, setProjects] = useState([]);
  const [Products1, setProducts1] = useState([]);
  const [Services, setServices] = useState([]);

  const token = sessionStorage.getItem("token");

  const navigate = useNavigate();

  useEffect(() => {
    datas2();
  }, []);

  const datas2 = () => {
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setContact(res.data.contactus[0]);
            setProducts1(res.data.productcategorys);
            setProjects(res.data.projectcategorys);
            setServices(res.data.services);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  const formsubmit1 = (e) => {
    e.preventDefault();
    Add1();
  };

  const [form1, setform1] = useState([]);

  const handleChange1 = (e) => {
    let myUser = { ...form1 };
    myUser[e.target.name] = e.target.value;
    setform1(myUser);
  };

  const Add1 = () => {
    const dataArray = {
      couponId: form1.couponCode,
      amount: productsvalueprice,
    };

    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/couponcheckstatusapi",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            localStorage.setItem("couponAmount", res.data.couponAmount);
            localStorage.setItem("coupon_Id", res.data.coupon_Id);
            const couponAmount = Number(res.data.couponAmount);
            const Amount = Number(localStorage.getItem("totalprice"));
            const dis = Amount - couponAmount;
            localStorage.setItem("coupondis", dis);
            settokens1(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const [showOtpForm, setShowOtpForm] = useState(false);

  const [form, setform] = useState([]);

  const [tokens, settokens] = useState(false);

  const [tokens1, settokens1] = useState(false);

  useEffect(() => {
    datasss();
  }, []);

  const datasss = () => {
    const tokens = sessionStorage.getItem("token");

    if (tokens != null) {
      settokens(true);
      settokens1(true);
    }
  };

  const formsubmit = (e) => {
    e.preventDefault();
    Add();
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const Add = () => {
    const dataArray = {
      phone: form.phone,
    };

    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/auth/logincheck",
        dataArray
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            sessionStorage.setItem("eamilotpid", res.data.data._id);
            sessionStorage.setItem("eamilVerfied", res.data.data.new_user);
            sessionStorage.setItem("mobilenos", res.data.data.phone);
            setform({
              phone: "",
            });
            setShowOtpForm(true);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const [form2, setform2] = useState([]);

  const formsubmit2 = (e) => {
    e.preventDefault();
    Add2();
  };

  const handleChange2 = (e) => {
    let myUser = { ...form2 };
    myUser[e.target.name] = e.target.value;
    setform2(myUser);
  };

  const emaildid = sessionStorage.getItem("eamilotpid");

  const Add2 = () => {
    const dataArray = {
      emailOtp: form2.otp,
      _id: emaildid,
    };

    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/auth/compareotp",
        dataArray
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            sessionStorage.setItem("token", res.data.token);
            sessionStorage.setItem("userid", res.data.data._id);
            settokens(true);
            settokens1(true);
            GetProfile();
            setform({
              otp: "",
            });
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const productsvalue = localStorage.getItem("cart");

  const productsvalueprice = localStorage.getItem("totalprice");

  const products = JSON.parse(productsvalue);

  const [form3, setform3] = useState([]);

  const formsubmit3 = (e) => {
    e.preventDefault();
    AddBooking();
  };

  const handleChange3 = (e) => {
    let myUser = { ...form3 };
    myUser[e.target.name] = e.target.value;
    setform3(myUser);
  };

  const copontype = localStorage.getItem("coupon_Id");

  const coponDIS = localStorage.getItem("couponAmount");

  const coponPRICE = localStorage.getItem("coupondis");

  const AddBooking = () => {
    const userid = sessionStorage.getItem("userid");

    const dataArray = {
      userId: userid,
      fullName: form3.fullName,
      email: form3.email,
      pincode: form3.pincode,
      address: form3.address,
      phone: form3.phone,
      state: form3.state,
      gst: "0",
      couponId: copontype,
      couponDiscount: coponDIS,
      subTotal: productsvalueprice,
      totalAmount: coponPRICE,
      services: products,
      slotDate: form3.date,
      slotTime: form3.time,
    };

    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/booking/addbooking",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);

            const totalss = parseInt(coponPRICE) * 100;

            const options = {
              key: "rzp_test_HJG5Rtuy8Xh2NB",
              amount: totalss,
              currency: "INR",
              name: "SS AIR",
              description: "SS AIR Transaction",
              image:
                "https://ssairconditioningsolutions.com/wp-content/uploads/2023/05/Logo-4-3-e1685259033945-281x127.png",

              handler: function (response) {
                var token = sessionStorage.getItem("token");

                const dataArrays = {
                  transactionId: response.razorpay_payment_id,
                  bookingId: res.data.booking._id,
                };

                axios
                  .post(
                    "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/booking/updatepayment",
                    dataArrays,
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  )
                  .then(
                    (res) => {
                      if (res.status === 200) {
                        toast(res.data.message);
                        navigate("/acmantanceservice");
                        localStorage.clear();
                        rzp1.close();
                      }
                    },
                    (error) => {
                      if (error.response && error.response.status === 400) {
                        toast(error.response.data.message);
                        rzp1.close();
                      }
                    }
                  );
              },
              prefill: {
                name: sessionStorage.getItem("name"),
                email: sessionStorage.getItem("email"),
                contact: sessionStorage.getItem("phone"),
              },
              notes: {
                address: "",
              },
              theme: {
                color: "#015A65",
              },
            };
            const rzp1 = new window.Razorpay(options);
            rzp1.open();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  useEffect(() => {
    GetProfile();
  }, []);

  const GetProfile = () => {
    const userid = sessionStorage.getItem("userid");

    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/auth/getprofile",
        { userId: userid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setform3(res.data.user);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
          }
        }
      );
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      {" "}
      <div className="wrapper">
        <Header
          Services={Services}
          Products={Products1}
          Projects={Projects}
          Contact={Contact}
        />

        <>
          <main className="page-content" id="fld">
            {/* <div
              className="page-title-area pt-210 pb-180"
              style={{
                backgroundImage: `url(${"https://img.freepik.com/free-photo/3d-rendering-ventilation-system_23-2149281318.jpg?t=st=1712848688~exp=1712852288~hmac=eca6c674fc37ba4eeb398f075383057c51d70cc35c6a1f7039116b6e6a7fdcc4&w=826"})`,
              }}
            >
              <div className="container">
                <div className="page-title-icon">
                  <i className="flaticon-air-conditioner hero__icon hero__icon1" />
                  <i className="flaticon-heating hero__icon hero__icon2" />
                  <i className="flaticon-vacuum-cleaner hero__icon hero__icon3" />
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="page-title text-center">
                      <h2 className="breadcrumb-title">Check Out</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div
              className="tm-section tm-checkout-area "
              style={{
                paddingTop: "20px",
                paddingBottom: "20px",
                background: "#F7F7F7",
              }}
            >
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-xl-12 col-lg-12">
                    <div className="tm-sectiontitle text-center">
                      <h3 className="text-dark pt-4" id="titling">
                        Check Out
                      </h3>
                      <span className="tm-sectiontitle-divider">
                        <img alt="divider icon" src="assets/images/ios4.png" />
                      </span>
                    </div>
                  </div>
                </div>
                {tokens === false ? (
                  <>
                    <div className="tm-checkout-coupon mt-5 bg-white">
                      <a
                        href="#Verify-Your-Mobile-Number"
                        data-toggle="collapse"
                      >
                        <span>Verify Your Mobile Number</span>
                      </a>
                      <div
                        id="Verify-Your-Mobile-Number"
                        className="collapse show"
                      >
                        <div className="card">
                          <div className="card-body">
                            {" "}
                            {!showOtpForm && (
                              <form
                                onSubmit={(e) => {
                                  formsubmit(e);
                                }}
                              >
                                <div className="row">
                                  <div className="col-md-3 col-sm-6">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="phone"
                                      required
                                      maxlength="10"
                                      minLength="10"
                                      pattern="[0-9]{10}"
                                      placeholder="Enter Mobile Number"
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      value={form.phone}
                                      onKeyPress={(e) => {
                                        // Allow only numeric input
                                        const charCode = e.which
                                          ? e.which
                                          : e.keyCode;
                                        if (charCode < 48 || charCode > 57) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                  </div>

                                  <div className="col-3 col-sm-6 mt-2">
                                    <button
                                      type="submit"
                                      style={{
                                        backgroundColor: "#2858A9",
                                        border: 0,
                                        color: "white",
                                        height: "35px",
                                      }}
                                    >
                                      Send <b />
                                    </button>
                                  </div>
                                </div>
                              </form>
                            )}
                            {showOtpForm && (
                              <form
                                onSubmit={(e) => {
                                  formsubmit2(e);
                                }}
                              >
                                {" "}
                                <div className="row">
                                  <div className="col-md-3 col-sm-6 ">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="otp"
                                      required
                                      maxlength="6"
                                      minLength="6"
                                      pattern="[0-9]{6}"
                                      placeholder="Enter otp Number"
                                      onChange={(e) => {
                                        handleChange2(e);
                                      }}
                                      value={form2.otp}
                                      onKeyPress={(e) => {
                                        // Allow only numeric input
                                        const charCode = e.which
                                          ? e.which
                                          : e.keyCode;
                                        if (charCode < 48 || charCode > 57) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="col-md-3 col-sm-6 ">
                                    <button
                                      type="submit"
                                      style={{
                                        backgroundColor: "#2858A9",
                                        border: 0,
                                        color: "white",
                                        height: "35px",
                                      }}
                                    >
                                      Submit <b />
                                    </button>
                                  </div>
                                </div>
                              </form>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div className="row mb-4 mt-3">
                  <div className="col-md-6 col-12">
                    <div>
                      <div className="card mb-5">
                        <div className="card-body">
                          <h4 className="small-title text-primary">
                            ORDER INFORMATION
                          </h4>
                          <div className="table-responsive">
                            <table className="table table-border tm-checkout-ordertable">
                              <thead>
                                <tr className="text-center">
                                  <th>S.No</th>
                                  <th>Product Name</th>
                                  <th>Product Type</th>
                                  <th>Product Quantity</th>
                                  <th>Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {products.map((data, index) => (
                                  <tr key={index} className="text-center">
                                    <td>{index + 1}</td>
                                    <td>
                                      <h5 className="text-primary">
                                        {data.name}
                                      </h5>
                                    </td>
                                    <td>{data.type}</td>
                                    <td>{data.quantity}</td>
                                    <td>{data.price}</td>
                                  </tr>
                                ))}
                              </tbody>

                              <tfoot>
                                <tr className="tm-checkout-total text-center">
                                  <td></td>
                                  <td
                                    colSpan={3}
                                    style={{
                                      color: "#686B77 ",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Sub Total :
                                  </td>
                                  <td>{productsvalueprice}/-</td>
                                </tr>

                                <tr className="tm-checkout-total text-center">
                                  <td></td>
                                  <td colSpan={3}>
                                    <span
                                      style={{
                                        color: "#686B77 ",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Discount Amount :
                                    </span>
                                  </td>
                                  <td>-{coponDIS}/-</td>
                                </tr>

                                <tr className="tm-checkout-total text-center">
                                  <td></td>
                                  <td
                                    colSpan={3}
                                    style={{
                                      color: "#686B77 ",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Total :
                                  </td>
                                  <td>{coponPRICE}/-</td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                          <div style={{ float: "right" }}>
                            <form
                              onSubmit={(e) => {
                                formsubmit1(e);
                              }}
                            >
                              {tokens1 === true && copontype == null ? (
                                <>
                                  <button
                                    color="primary"
                                    onClick={toggle}
                                    style={{
                                      marginTop: 6,
                                      backgroundColor: "#2858A9",
                                      border: 0,
                                      color: "white",
                                      height: "35px",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    Have a coupon code?
                                  </button>
                                  <Collapse isOpen={isOpen} {...args}>
                                    <input
                                      type="text"
                                      id="coupon-field"
                                      placeholder="Enter coupon code"
                                      required="required"
                                      onChange={(e) => {
                                        handleChange1(e);
                                      }}
                                      name="couponCode"
                                      value={form1.couponCode}
                                      className="mt-2 mb-2"
                                    />
                                    <button
                                      type="submit"
                                      style={{
                                        marginTop: 6,
                                        backgroundColor: "#2858A9",
                                        border: 0,
                                        color: "white",
                                        height: "35px",
                                      }}
                                    >
                                      Apply <b />
                                    </button>
                                  </Collapse>
                                </>
                              ) : (
                                <></>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    {tokens === true ? (
                      <>
                        <div>
                          <div
                            id="Verify-Your-Mobile-signin"
                            className="collapse show"
                          >
                            <form
                              onSubmit={(e) => {
                                formsubmit3(e);
                              }}
                            >
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="card">
                                    <div className="card-body">
                                      <h4 className="small-title text-primary">
                                        BILLING INFORMATION
                                      </h4>

                                      <div className="row">
                                        <div className="col-md-6 mb-3">
                                          <label>Full Name</label>{" "}
                                          <span className="text-danger">*</span>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="name"
                                            required
                                            placeholder="Enter Full Name"
                                            onChange={(e) => {
                                              handleChange3(e);
                                            }}
                                            value={form3.name}
                                          />
                                        </div>

                                        <div className="col-md-6 mb-3">
                                          <label>Email Id </label>{" "}
                                          <span className="text-danger">*</span>
                                          <input
                                            type="email"
                                            className="form-control"
                                            name="email"
                                            required
                                            placeholder="Enter Your Email Id"
                                            onChange={(e) => {
                                              handleChange3(e);
                                            }}
                                            value={form3.email}
                                          />
                                        </div>

                                        <div className="col-md-6 mb-3">
                                          <label>Phone Number</label>{" "}
                                          <span className="text-danger">*</span>
                                          <input
                                            type="text"
                                            name="phone"
                                            required
                                            placeholder="Enter Your Phone Number"
                                            onChange={(e) => {
                                              handleChange3(e);
                                            }}
                                            value={form3.phone}
                                            maxlength="10"
                                            minLength="10"
                                            pattern="[0-9]{10}"
                                            onKeyPress={(e) => {
                                              // Allow only numeric input
                                              const charCode = e.which
                                                ? e.which
                                                : e.keyCode;
                                              if (
                                                charCode < 48 ||
                                                charCode > 57
                                              ) {
                                                e.preventDefault();
                                              }
                                            }}
                                          />
                                        </div>

                                        <div className="col-md-6 mb-3">
                                          <label>Date</label>{" "}
                                          <span className="text-danger">*</span>
                                          <input
                                            type="date"
                                            name="date"
                                            required
                                            placeholder="Enter Date"
                                            onChange={(e) => {
                                              handleChange3(e);
                                            }}
                                            value={form3.date}
                                          />
                                        </div>

                                        <div className="col-md-6 mb-3">
                                          <label>Time</label>{" "}
                                          <span className="text-danger">*</span>
                                          <input
                                            type="time"
                                            name="time"
                                            required
                                            placeholder="Enter Time"
                                            onChange={(e) => {
                                              handleChange3(e);
                                            }}
                                            value={form3.time}
                                          />
                                        </div>

                                        <div className="col-md-6 mb-3">
                                          <label>Address</label>{" "}
                                          <span className="text-danger">*</span>
                                          <input
                                            type="text"
                                            placeholder="Apartment, Street Address ,Location"
                                            name="address"
                                            required
                                            onChange={(e) => {
                                              handleChange3(e);
                                            }}
                                            value={form3.address}
                                          />
                                        </div>

                                        <div className="col-md-6 mb-3">
                                          <label>State</label>{" "}
                                          <span className="text-danger">*</span>
                                          <input
                                            type="text"
                                            placeholder="Enter State"
                                            name="state"
                                            required
                                            onChange={(e) => {
                                              handleChange3(e);
                                            }}
                                            value={form3.state}
                                          />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                          <label>Zip / Postcode</label>{" "}
                                          <span className="text-danger">*</span>
                                          <input
                                            type="text"
                                            placeholder="Enter  Zip / Postcode"
                                            name="pincode"
                                            required
                                            maxlength="6"
                                            minLength="6"
                                            pattern="[0-9]{6}"
                                            onChange={(e) => {
                                              handleChange3(e);
                                            }}
                                            value={form3.pincode}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-12 mb-3">
                                        <button
                                          type="submit"
                                          style={{
                                            backgroundColor: "#2858A9",
                                            border: 0,
                                            color: "white",
                                            height: "40px",
                                            float: "right",
                                          }}
                                        >
                                          Place Order <b />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
        <ToastContainer />
        <Footer Services={Services} Products={Products1} Contact={Contact} />
      </div>
    </div>
  );
}

export default CheckOut;
