import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { NavLink } from "react-router-dom";

function AboutUs() {
  const api = "https://api.ssairconditioners.com/";
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    datas();
  }, []);

  const [clients, setclients] = useState([]);

  const [HomeContant, setHomeContant] = useState([]);

  const [Contact, setContact] = useState([]);
  const [Projects, setProjects] = useState([]);
  const [Products1, setProducts1] = useState([]);
  const [Services, setServices] = useState([]);

  const datas = () => {
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setclients(res.data.financing);
            setHomeContant(res.data.homecontents[0]);
            setContact(res.data.contactus[0]);
            setProducts1(res.data.productcategorys);
            setProjects(res.data.projectcategorys);
            setServices(res.data.services);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
            <span class="loader"></span>
          </div>
        </>
      ) : (
        <>
          <div className="wrapper">
            <Header
              Services={Services}
              Products={Products1}
              Projects={Projects}
              Contact={Contact}
            />

            <main className="page-content" id="fld">
              <div
                className="page-title-area pt-210 pb-180"
                style={{
                  backgroundImage: `url(${"https://img.freepik.com/free-photo/3d-rendering-ventilation-system_23-2149281318.jpg?t=st=1712848688~exp=1712852288~hmac=eca6c674fc37ba4eeb398f075383057c51d70cc35c6a1f7039116b6e6a7fdcc4&w=826"})`,
                }}
              >
                <div className="container">
                  <div className="page-title-icon">
                    <i className="flaticon-air-conditioner hero__icon hero__icon1" />
                    <i className="flaticon-heating hero__icon hero__icon2" />
                    <i className="flaticon-vacuum-cleaner hero__icon hero__icon3" />
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="page-title text-center">
                        <h2 className="breadcrumb-title">
                          {HomeContant.financingTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fulid branchShowcase pt-2 pb-2 ">
                <div
                  id="tm-financing-area"
                  className="tm-financing-area tm-section"
                >
                  <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-12">
                      <div className="tm-sectiontitle text-center">
                        <h2 className="text-primary mt-3">
                          {" "}
                          {HomeContant.financingTitle}
                        </h2>
                        <span className="tm-sectiontitle-divider">
                          <img
                            alt="divider icon"
                            src="assets/images/ios4.png"
                          />
                        </span>
                        <p>{HomeContant.financingDescription}</p>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div
                      id="tm-pricing-area"
                      className="tm-pricing-plan tm-section tm-padding-section "
                    >
                      <div className="container">
                        <div className="row mt-30-reverse justify-content-center">
                          {clients.map((data, i) => {
                            return (
                              <>
                                <div
                                  className="col-lg-4 col-md-6 col-sm-6 col-12 mt-30"
                                  key={i}
                                >
                                  <div
                                    className="tm-pricebox text-center  is-active"
                                    style={{
                                      background: "#F7F7F7",
                                      height: "500px",
                                    }}
                                  >
                                    <div className="tm-pricebox-price">
                                      <img
                                        src={api + data.image}
                                        style={{
                                          height: "40px",
                                          margin: "auto",
                                          width: "90%",
                                        }}
                                      />
                                    </div>
                                    <h5
                                      className="tm-pricebox-title pt-3 text-primary"
                                      style={{ height: "60px" }}
                                    >
                                      {data.title.slice(0, 40)}
                                    </h5>
                                    <ul>
                                      <li style={{ height: "80px" }}>
                                        {data.description.slice(0, 100)}
                                      </li>
                                      <li className="pt-4 text-danger">
                                        <span className="text-primary">
                                          {" "}
                                          Expire Date :
                                        </span>{" "}
                                        {data.expiredDate}
                                      </li>
                                    </ul>
                                    <hr></hr>
                                    <button
                                      type="button"
                                      style={{
                                        width: "40%",
                                        marginTop: 6,
                                        backgroundColor: "#2858A9",
                                        border: 0,
                                        color: "white !important",
                                        height: "30px",
                                      }}
                                    >
                                      <NavLink to="/acmantance">
                                        <span
                                          style={{
                                            color: "white",
                                          }}
                                        >
                                          Book Now
                                        </span>
                                      </NavLink>
                                    </button>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>

            <Footer
              Services={Services}
              Products={Products1}
              Contact={Contact}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default AboutUs;
