import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

function ViewSplits() {
  const [Products, setProducts] = useState([]);
  const [keyFeatures, setkeyFeatures] = useState([]);

  useEffect(() => {
    datas();
  }, []);

  const [activeTab1, setactiveTab1] = useState("1");

  const toggle1 = (tab) => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  };

  const api = "https://api.ssairconditioners.com/";

  const getid = sessionStorage.getItem("splitIds");

  const datas = () => {
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/getproductdetailsbyid",
        { id: getid },
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setProducts(res.data.product[0]);
            setkeyFeatures(res.data.product[0].keyFeatures);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
          }
        }
      );
  };

  const [Contact, setContact] = useState([]);
  const [Projects, setProjects] = useState([]);
  const [Products1, setProducts1] = useState([]);
  const [Services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    datas1();
  }, []);

  const datas1 = () => {
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setContact(res.data.contactus[0]);
            setProducts1(res.data.productcategorys);
            setProjects(res.data.projectcategorys);
            setServices(res.data.services);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  const [form, setform] = useState([]);

  const formsubmit = (e) => {
    e.preventDefault();
    Add();
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const Add = () => {
    const dataArray = {
      name: form.name,
      email: form.email,
      phoneNumber: form.phoneNumber,
      subject: form.subject,
      description: form.description,
      address: form.address,
      productId: Products._id,
    };

    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/productenquiry/addproductenquiry",
        dataArray
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            setform({
              name: "",
              email: "",
              phoneNumber: "",
              subject: "",
              description: "",
              address: "",
            });
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
            <span class="loader"></span>
          </div>
        </>
      ) : (
        <>
          <div className="wrapper">
            <Header
              Services={Services}
              Products={Products1}
              Projects={Projects}
              Contact={Contact}
            />

            <main className="page-content" id="fld">
              <div className="tm-section products-details-page-content bg-white pt-5">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-9 col-12">
                      <div className="tm-prodetails">
                        <div className="row">
                          <div
                            className="col-lg-6 col-md-6 col-sm-10 col-12"
                            style={{ background: "#F7F7F7" }}
                          >
                            <div className="tm-prodetails-images">
                              <div className="tm-prodetails-largeimages">
                                <div className="tm-prodetails-largeimage">
                                  <a
                                    data-fancybox="tm-prodetails-imagegallery"
                                    href={api + Products.image}
                                    data-caption="Product Zoom Image 1"
                                  >
                                    <img
                                      src={api + Products.image}
                                      alt="product image"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-12">
                            <div className="tm-prodetails-content">
                              <h3 className="tm-prodetails-title ">
                                {Products.name}
                              </h3>
                              <div className="tm-ratingbox">
                                <span className="is-active">
                                  <i className="ion-ios-star" />
                                </span>
                                <span className="is-active">
                                  <i className="ion-ios-star" />
                                </span>
                                <span className="is-active">
                                  <i className="ion-ios-star" />
                                </span>
                                <span className="is-active">
                                  <i className="ion-ios-star" />
                                </span>
                                <span className="is-active">
                                  <i className="ion-ios-star" />
                                </span>
                              </div>
                              <p className="tm-prodetails-availability">
                                Availalbe: <span>In Stock</span>
                              </p>
                              <div className="tm-prodetails-price">
                                <span>Price :   <span>{Products.price} /-</span></span>
                              </div>
                              <p>{Products.description}</p>
                              <div className="tm-prodetails-categories">
                                <div className="row">
                                  <div className="col-3">
                                    <h6>Model :</h6>
                                  </div>
                                  <div className="col-4">
                                    <ul>
                                      <li>
                                        <a>{Products.modelName}</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="tm-prodetails-categories">
                                <div className="row">
                                  <div className="col-3">
                                    <h6>Refrigant :</h6>
                                  </div>
                                  <div className="col-4">
                                    <ul>
                                      <li>
                                        <a>{Products.refrigant}</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="tm-prodetails-tags">
                                <div className="row">
                                  <div className="col-3">
                                    <h6>Tonnage :</h6>
                                  </div>
                                  <div className="col-4">
                                    <ul>
                                      <li>
                                        <a>{Products.tonnage}</a>
                                      </li>
                                    </ul>{" "}
                                  </div>
                                </div>
                              </div>
                              <div className="tm-prodetails-share"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tble-resp mt-5"
                        dangerouslySetInnerHTML={{
                          __html: Products.specifications,
                        }}
                      ></div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="single-widget widget-popularproduct ">
                        <h6 className="widget-title">Key Features</h6>

                        <ul>
                          {keyFeatures.map((item, i) => (
                            <li>
                              <a
                                className="widget-popularproduct-image"
                                key={i}
                              >
                                <img
                                  src={api + item.image}
                                  alt="product thumbnail"
                                />
                              </a>
                              <div className="widget-popularproduct-content">
                                <h6>
                                  <a>{item.label}</a>
                                </h6>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-12">
                  <div className="pb-2 mt-3" id="rtx">
                    <div className="container">
                      <div className="row justify-content-center pt-5">
                        <div className="col-lg-12 pb-4">
                          <div style={{ border: "1px solid white" }}>
                            <h4
                              className="text-center text-white  pt-3"
                              style={{
                                borderBottom: "1px solid white",
                                borderRadius: "10px",
                              }}
                            >
                              Contact Us
                            </h4>{" "}
                            <div className="m-3">
                              <form
                                onSubmit={(e) => {
                                  formsubmit(e);
                                }}
                              >
                                <div className="row justify-content-center ">
                                  <div className="col-lg-6 col-md-6 col-12">
                                    <div>
                                      <label className="text-white">Name</label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Name"
                                        name="name"
                                        required
                                        onChange={(e) => {
                                          handleChange(e);
                                        }}
                                        value={form.name}
                                      />
                                    </div>
                                    <div>
                                      <label className="text-white">
                                        Email
                                      </label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        name="email"
                                        required
                                        onChange={(e) => {
                                          handleChange(e);
                                        }}
                                        value={form.email}
                                      />
                                    </div>
                                    <div>
                                      <label className="text-white">
                                        Phone Number
                                      </label>
                                      <input
                                        type="text"
                                        placeholder="Phone Number"
                                        name="phoneNumber"
                                        required
                                        onChange={(e) => {
                                          handleChange(e);
                                        }}
                                        maxlength="10"
                                        minLength="10"
                                        pattern="[0-9]{10}"
                                        value={form.phoneNumber}
                                        className="form-control"
                                        onKeyPress={(e) => {
                                          // Allow only numeric input
                                          const charCode = e.which
                                            ? e.which
                                            : e.keyCode;
                                          if (charCode < 48 || charCode > 57) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-12">
                                    <div>
                                      <label className="text-white">
                                        Product Name
                                      </label>
                                      <input
                                        type="text"
                                        placeholder="Product Name"
                                        name="subject"
                                        className="form-control"
                                        required
                                        onChange={(e) => {
                                          handleChange(e);
                                        }}
                                        value={form.subject}
                                      />
                                    </div>
                                    <div>
                                      <label className="text-white">
                                        Message
                                      </label>
                                      <textarea
                                        cols={30}
                                        rows={5}
                                        placeholder="Message"
                                        name="description"
                                        className="form-control"
                                        required
                                        onChange={(e) => {
                                          handleChange(e);
                                        }}
                                        value={form.description}
                                      />
                                    </div>
                                    <div
                                      style={{ float: "right" }}
                                      className="mt-4"
                                    >
                                      <button
                                        type="submit"
                                        style={{
                                          backgroundColor: "#2858A9",
                                          border: 0,
                                          color: "white",
                                          height: "35px",
                                        }}
                                      >
                                        Submit Now
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <ToastContainer />
            <Footer
              Services={Services}
              Products={Products1}
              Contact={Contact}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default ViewSplits;
