import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import Slider from "react-slick";

function AboutUs() {
  const api = "https://api.ssairconditioners.com/";
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    datas();
  }, []);

  const [clients, setclients] = useState([]);
  const [clients1, setclients1] = useState([]);
  const [HomeContant, setHomeContant] = useState([]);
  const [Contact, setContact] = useState([]);
  const [Projects, setProjects] = useState([]);
  const [Products1, setProducts1] = useState([]);
  const [Services, setServices] = useState([]);

  const datas = () => {
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setclients(res.data.brandClients);
            setclients1(res.data.clients);
            setHomeContant(res.data.homecontents[0]);
            setContact(res.data.contactus[0]);
            setProducts1(res.data.productcategorys);
            setProjects(res.data.projectcategorys);
            setServices(res.data.services);

            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  const settingss1 = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: true,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
            <span class="loader"></span>
          </div>
        </>
      ) : (
        <>
          <div className="wrapper">
            <Header
              Services={Services}
              Products={Products1}
              Projects={Projects}
              Contact={Contact}
            />

            <main className="page-content" id="fld">
              <div
                className="page-title-area pt-210 pb-180"
                style={{
                  backgroundImage: `url(${"https://img.freepik.com/free-photo/3d-rendering-ventilation-system_23-2149281318.jpg?t=st=1712848688~exp=1712852288~hmac=eca6c674fc37ba4eeb398f075383057c51d70cc35c6a1f7039116b6e6a7fdcc4&w=826"})`,
                }}
              >
                <div className="container">
                  <div className="page-title-icon">
                    <i className="flaticon-air-conditioner hero__icon hero__icon1" />
                    <i className="flaticon-heating hero__icon hero__icon2" />
                    <i className="flaticon-vacuum-cleaner hero__icon hero__icon3" />
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="page-title text-center">
                        <h2 className="breadcrumb-title">Our Clients</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container pt-4">
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-8">
                    <div className="tm-sectiontitle text-center">
                      <h2 className="text-primary">
                        {HomeContant.clientTitle}
                      </h2>
                      <span className="tm-sectiontitle-divider">
                        <img alt="divider icon" src="assets/images/ios4.png" />
                      </span>
                      <p>{HomeContant.clientDescription}</p>
                    </div>
                  </div>
                </div>
              </div>
              <section
                class="clients-logo py-5"
                style={{ backgroundColor: "#F7F7F7" }}
              >
                <div class="container">
                  <div class="row">
                    {clients1.map((data, i) => {
                      return (
                        <>
                          <div
                            class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-3 clientsImage mx-0 p-1 pt-4"
                            key={i}
                          >
                            <img
                              src={api + data.image}
                              alt="Sandy's Tower Logo"
                              id="zoom"
                              style={{ height: "120px", width: "80%" }}
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </section>

              <div className="container brands-section">
                <div className="row py-xl-5 py-lg-4 py-md-4 py-sm-4 py-2 px-0">
                  <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 px-sm-1 px-2 py-1">
                    <div className="pt-2">
                      <h4
                        className="text-left font-weight-bold text-dark"
                        style={{
                          fontFamily: "Poppins",
                          color: "#888",
                          letterSpacing: "0.3px",
                        }}
                      >
                        We are{" "}
                        <span
                          className="brands-title-end"
                          style={{ color: "#2858A9" }}
                        >
                          Associated
                        </span>
                      </h4>
                    </div>
                  </div>
                  <div
                    className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 px-sm-1 px-2 py-1 "
                    style={{ background: "white" }}
                  >
                    <Slider {...settingss1}>
                      {clients.map((data, i) => {
                        return (
                          <>
                            <div key={i}>
                              <div>
                                <img
                                  src={api + data.image}
                                  alt="Brand Image"
                                  style={{
                                    height: "50px",
                                    width: "70%",
                                    margin: "auto",
                                    padding: "2px",
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              </div>
            </main>
            <Footer
              Services={Services}
              Products={Products1}
              Contact={Contact}
            />
          </div>{" "}
        </>
      )}
    </div>
  );
}

export default AboutUs;
