import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import axios from "axios";

function AboutUs() {
  const databyid = (data) => {
    sessionStorage.setItem("splitIds", data._id);
    window.location.href = "/viewproduct";
  };

  useEffect(() => {
    datas();
  }, []);

  const [Products, setProducts] = useState([]);

  const [Products2, setProducts2] = useState([]);

  const getid = sessionStorage.getItem("Idss");

  const api = "https://api.ssairconditioners.com/";

  const datas = () => {
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/getprodyctById",
        { id: getid },
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setProducts(res.data.productcategory);
            setProducts2(res.data.product);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
          }
        }
      );
  };

  const [Contact, setContact] = useState([]);
  const [Projects, setProjects] = useState([]);
  const [Products1, setProducts1] = useState([]);
  const [Services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    datas1();
  }, []);

  const datas1 = () => {
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setContact(res.data.contactus[0]);
            setProducts1(res.data.productcategorys);
            setProjects(res.data.projectcategorys);
            setServices(res.data.services);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
            <span class="loader"></span>
          </div>
        </>
      ) : (
        <>
          <div className="wrapper">
            <Header
              Services={Services}
              Products={Products1}
              Projects={Projects}
              Contact={Contact}
            />

            <main className="page-content" id="fld">
              <div
                className="page-title-area pt-210 pb-180"
                style={{
                  backgroundImage: `url(${"https://img.freepik.com/free-photo/3d-rendering-ventilation-system_23-2149281318.jpg?t=st=1712848688~exp=1712852288~hmac=eca6c674fc37ba4eeb398f075383057c51d70cc35c6a1f7039116b6e6a7fdcc4&w=826"})`,
                }}
              >
                <div className="container">
                  <div className="page-title-icon">
                    <i className="flaticon-air-conditioner hero__icon hero__icon1" />
                    <i className="flaticon-heating hero__icon hero__icon2" />
                    <i className="flaticon-vacuum-cleaner hero__icon hero__icon3" />
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="page-title text-center">
                        <h2 className="breadcrumb-title"> {Products.title}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="container pt-2">
                <div className="row justify-content-center">
                  <div className="col-xl-12 col-lg-12">
                    <div className="tm-sectiontitle text-center">
                      <h2 className="text-primary mt-3">
                        {" "}
                        {Products.title}
                      </h2>
                      <span className="tm-sectiontitle-divider">
                        <img
                          alt="divider icon"
                          src="assets/images/ios4.png"
                        />
                      </span>
                      <p>{Products.description}</p>
                    </div>
                  </div>
                </div>
            
                {/* <div className="row productMain mx-lg-0 mx-md-0 mx-sm-0 mx-0 py-3 ">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-left ">
                    <p className="product-text text-left text-justify text-break px-xl-5 px-md-2 px-2 mt-3">
                      {Products.description}
                    </p>
                  </div>
                </div> */}
              </div>
              <div className="tm-section products-page-content pt-3 pb-5 bg-white mb-5 ">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 col-12">
                      <div className="tm-shop-products">
                        <div className="row mt-30-reverse">
                          {Products2.map((data, i) => {
                            return (
                              <>
                                <div
                                  className="col-md-4 mt-3"
                                  key={i}
                                  id="opsd"
                                  onClick={() => {
                                    databyid(data);
                                  }}
                                >
                                  <div
                                    className="product-item-info text-center"
                                    data-container="product-grid"
                                  >
                                    <a>
                                      <span className="product-image-container">
                                        <span
                                          className="product-image-wrapper"
                                          style={{ paddingBottom: "100%" }}
                                        >
                                          <img
                                            className="product-image-photo"
                                            src={api + data.image}
                                            height="300px"
                                          />
                                        </span>
                                      </span>
                                    </a>
                                    <div className="product details product-item-details">
                                      <strong className="hjj">
                                        <a className="hjj">{data.name}</a>
                                      </strong>
                                      <h6>
                                        <div className="tm-ratingbox">
                                          <span className="is-active">
                                            <i className="ion-ios-star" />
                                          </span>
                                          <span className="is-active">
                                            <i className="ion-ios-star" />
                                          </span>
                                          <span className="is-active">
                                            <i className="ion-ios-star" />
                                          </span>
                                          <span className="is-active">
                                            <i className="ion-ios-star-half" />
                                          </span>
                                          <span>
                                            <i className="ion-ios-star" />
                                          </span>
                                        </div>
                                      </h6>
                                      <h6>{data.price}/-</h6>
                                      <div
                                        className="price-box price-final_price text-center"
                                        data-role="priceBox"
                                        data-product-id={772}
                                        data-price-box="product-id-772"
                                      >
                                        <span className="special-price">
                                          <span className="price-container price-final_price tax weee">
                                            <span
                                              id="product-price-772"
                                              data-price-type="finalPrice"
                                              className="price-wrapper "
                                            >
                                              <span className="price">
                                                {data.description.slice(0, 100)}
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      style={{ width: "100%" }}
                                      className="mb-2 text-center"
                                    >
                                      <button
                                        type="button"
                                        style={{
                                          width: "40%",
                                          marginTop: 6,
                                          backgroundColor: "#2858A9",
                                          border: 0,
                                          color: "white",
                                          height: "30px",
                                        }}
                                        onClick={() => {
                                          databyid(data);
                                        }}
                                      >
                                        <span>View More</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <Footer
              Services={Services}
              Products={Products1}
              Contact={Contact}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default AboutUs;
