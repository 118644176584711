import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Card from "react-bootstrap/Card";

function AboutUs() {
  const navigate = useNavigate();

  const databyid = (data) => {
    sessionStorage.setItem("Catid", data._id);
    navigate("/acmantanceservice");
  };

  useEffect(() => {
    datas();
  }, []);

  const [Service, setService] = useState([]);

  const [Service1, setService1] = useState([]);

  const api = "https://api.ssairconditioners.com/";

  const datas = () => {
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/getamcbyid",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setService(res.data.amc);
            setService1(res.data.amcService);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
          }
        }
      );
  };

  const trimmedBannerImage = Service.bannerImage
    ? Service.bannerImage.trim()
    : "";

  const imageUrl = trimmedBannerImage ? api + trimmedBannerImage : "";

  const backgroundImageStyle = {
    backgroundImage: `url(${imageUrl})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: "500px",
  };

  const captionStyle = {
    top: "30%",
  };

  const [Contact, setContact] = useState([]);
  const [Projects, setProjects] = useState([]);
  const [Products1, setProducts1] = useState([]);
  const [Services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    datas1();
  }, []);

  const datas1 = () => {
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setContact(res.data.contactus[0]);
            setProducts1(res.data.productcategorys);
            setProjects(res.data.projectcategorys);
            setServices(res.data.services);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
            <span class="loader"></span>
          </div>
        </>
      ) : (
        <>
          <div className="wrapper">
            <Header
              Services={Services}
              Products={Products1}
              Projects={Projects}
              Contact={Contact}
            />

            <main className="page-content" id="fld">
              <div
                id="tm-about-area"
                className="tm-about-area tm-section  bg-white"
              >
                <div
                  className="page-title-area pt-210 pb-180"
                  style={{ backgroundImage: `url(${imageUrl})` }}
                >
                  <div className="container">
                    <div className="page-title-icon">
                      <i className="flaticon-air-conditioner hero__icon hero__icon1" />
                      <i className="flaticon-heating hero__icon hero__icon2" />
                      <i className="flaticon-vacuum-cleaner hero__icon hero__icon3" />
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="page-title text-center">
                          <h2 className="breadcrumb-title">{Service.title}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container mt-5 mb-5">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <img src={api + Service.image}></img>
                    </div>
                    <div className="col-lg-6">
                      <div className="tm-about-content">
                        <h2 className="text-primary">{Service.title}</h2>
                        <p>{Service.description}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="tm-about-area"
                  className="tm-about-area tm-section bg-white mb-5"
                >
                  <h3
                    className="text-center font-weight-bold text-uppercase m-0 "
                    style={{ color: "black" }}
                  >
                    <span className="dd-head">Air Conditioner Services</span>
                  </h3>
                  <div class="container">
                    <div class="row ">
                      {Service1.map((data, i) => {
                        return (
                          <>
                            <div
                              class="col-lg-4 col-md-6 col-12 mt-3"
                              key={i}
                              style={{ borderRadius: "5px" }}
                            >
                              <Card style={{ width: "100%" }} id="opsds">
                                <Card.Img
                                  variant="top"
                                  src={api + data.image}
                                  height="220px"
                                  onClick={() => {
                                    databyid(data);
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              
                                <Card.Body>
                                  <Card.Title className="text-center">
                                    <b style={{ color: "#2858A9" }}>
                                      {data.title}
                                    </b>
                                    <hr></hr>
                                  </Card.Title>
                                  <Card.Text className="text-center ">
                                    {data.description.slice(0, 200)}...
                                  </Card.Text>

                                  <hr></hr>
                                <div className="text-center ">
                                  <button
                                    type="button"
                                    style={{
                                      width: "40%",
                                      marginTop: 6,
                                      backgroundColor: "#2858A9",
                                      border: 0,
                                      color: "white",
                                      height: "30px",
                                    }}
                                    onClick={() => {
                                      databyid(data);
                                    }}
                                  >
                                    <span>Book Now</span>
                                  </button>
                                </div>
                                </Card.Body>
                              </Card>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div
                  id="tm-about-area"
                  className="tm-about-area tm-section bg-white mb-5"
                >
                  <h3
                    className="text-center font-weight-bold text-uppercase m-0 "
                    style={{ color: "black" }}
                  ></h3>
                  <div class="container">
                    <div class="row">
                      <div class="col-md-12">
                        <div style={{ width: "100%" }}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: Service.largeDescription,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <Footer
              Services={Services}
              Products={Products1}
              Contact={Contact}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default AboutUs;
