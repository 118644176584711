import Home from "./Comonents/Home";
import AboutUs from "./Comonents/AboutUs";
import Clients from "./Comonents/Clients";
import Carrers from "./Comonents/Carrers";
import Contact from "./Comonents/Contact";
import CheckOut from "./Comonents/Services/CheckOut";
import SplitsAc from "./Comonents/Products/SplitsAc";
import ViewSplits from "./Comonents/Products/ViewSplits";
import AcMantance from "./Comonents/Services/AcMantance";
import Residential from "./Comonents/Projects/Residential";
import { Routes, Route } from "react-router-dom";
import AcRepairExchange from "./Comonents/Services/AcRepairExchange";
import AcMantanceService from "./Comonents/Services/AcMantanceService";
import Login from "./Comonents/Login";
import SignUp from "./Comonents/SignUp";
import Cart from "./Comonents/Cart";
import Otp from "./Comonents/Otp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/financing" element={<Carrers />} />
        <Route path="/contact" element={<Contact />} />

        {/* Projects */}
        <Route path="/project" element={<Residential />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/otp" element={<Otp />} />

        {/* products */}
        <Route path="/products" element={<SplitsAc />} />
        <Route path="/viewproduct" element={<ViewSplits />} />

        {/* Services */}
        <Route path="/service" element={<AcRepairExchange />} />
        <Route path="/acmantance" element={<AcMantance />} />
        <Route path="/acmantanceservice" element={<AcMantanceService />} />
        <Route path="/chekout" element={<CheckOut />} />
      </Routes>
    </div>
  );
}

export default App;
