import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function AboutUs() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    datas();
  }, []);

  const [Contact, setContact] = useState([]);
  const [Projects, setProjects] = useState([]);
  const [Products1, setProducts1] = useState([]);
  const [Services, setServices] = useState([]);

  const datas = () => {
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setContact(res.data.contactus[0]);
            setProducts1(res.data.productcategorys);
            setProjects(res.data.projectcategorys);
            setServices(res.data.services);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  const navigate = useNavigate();

  const [form, setform] = useState([]);

  const formsubmit = (e) => {
    e.preventDefault();
    Add();
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const emaildid = sessionStorage.getItem("eamilotpid");
  const emailotp = sessionStorage.getItem("eamilVerfied");

  const Add = () => {
    const dataArray = {
      emailOtp: form.otp,
      _id: emaildid,
    };

    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/auth/compareotp",
        dataArray
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            sessionStorage.setItem("userid", res.data.data._id);
            setform({
              otp: "",
            });
            if (emailotp == "no") {
              sessionStorage.setItem("token", res.data.token);
              navigate("/signup");
            } else {
              sessionStorage.setItem("token", res.data.token);
              navigate("/cart");
            }
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
            <span class="loader"></span>
          </div>
        </>
      ) : (
        <>
          <div className="wrapper">
            <Header
              Services={Services}
              Products={Products1}
              Projects={Projects}
              Contact={Contact}
            />

            <main class="page-content" id="fld">
              <section class="bg-grey p-3 p-md-4 p-xl-5">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-12 col-xxl-11">
                      <div class="card border-light-subtle shadow-sm">
                        <div class="row g-0">
                          <div class="col-12 col-md-6">
                            <img
                              class="img-fluid rounded-start w-100 h-100 object-fit-cover"
                              loading="lazy"
                              src="https://static.vecteezy.com/system/resources/previews/016/071/197/non_2x/desktop-login-isometric-illustration-light-gradient-suitable-for-mobile-app-website-banner-diagrams-infographics-and-other-graphic-assets-vector.jpg"
                              alt="Welcome back you've been missed!"
                            />
                          </div>
                          <div class="col-12 col-md-6 d-flex align-items-center justify-content-center">
                            <div class="col-12 col-lg-11 col-xl-10">
                              <div class="card-body p-3 p-md-4 p-xl-5">
                                <div class="row">
                                  <div class="col-12">
                                    <div class="mb-5">
                                      <div class="text-center mb-4">
                                        <h2 className="text-primary">OTP</h2>
                                      </div>
                                      <h4 class="text-center">
                                        Welcome back you've been missed!
                                      </h4>
                                    </div>
                                  </div>
                                </div>

                                <form
                                  onSubmit={(e) => {
                                    formsubmit(e);
                                  }}
                                >
                                  <div class="row gy-3 overflow-hidden">
                                    <div class="col-12">
                                      <div class=" mb-3">
                                        <label
                                          htmlFor="email"
                                          className="form-label"
                                        >
                                          Enter OTP{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="otp"
                                          required
                                          placeholder="Enter Otp : 123456"
                                          onChange={(e) => {
                                            handleChange(e);
                                          }}
                                          value={form.otp}
                                        />
                                      </div>
                                    </div>

                                    <div class="col-12">
                                      <div class="d-grid">
                                        <button
                                          class="btn btn-primary btn-lg w-100 mt-4"
                                          type="submit"
                                        >
                                          Enter OTP
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
            <ToastContainer />
            <Footer
              Services={Services}
              Products={Products1}
              Contact={Contact}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default AboutUs;
