import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import axios from "axios";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

function AboutUs() {
  useEffect(() => {
    datas();
  }, []);

  const [activeTab, setActiveTab] = useState(0);

  const toggleTab = (tabIndex) => {
    if (activeTab !== tabIndex) {
      setActiveTab(tabIndex);
    }
  };

  const [isLoading, setIsLoading] = useState(true);

  const [Projects2, setProjects2] = useState([]);

  const [Projects1, setProjects1] = useState([]);

  const getid = sessionStorage.getItem("Idsss");

  const api = "https://api.ssairconditioners.com/";

  const datas = () => {
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/getprojectbyid",
        { id: getid },
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setProjects2(res.data.projectservice);
            setProjects1(res.data.serviceprocess);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
          }
        }
      );
  };

  const [Contact, setContact] = useState([]);
  const [Projects, setProjects] = useState([]);
  const [Products1, setProducts1] = useState([]);
  const [Services, setServices] = useState([]);
  const [Projectss, setProjectss] = useState([]);

  useEffect(() => {
    datas1();
  }, []);

  const datas1 = () => {
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setContact(res.data.contactus[0]);
            setProducts1(res.data.productcategorys);
            setProjects(res.data.projectcategorys);
            setServices(res.data.services);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
            <span class="loader"></span>
          </div>
        </>
      ) : (
        <>
          <div className="wrapper">
            <Header
              Services={Services}
              Products={Products1}
              Projects={Projects}
              Contact={Contact}
            />
            <main className="page-content mb-5" id="fld">
              <div
                className="page-title-area pt-210 pb-180"
                style={{
                  backgroundImage: `url(${"https://img.freepik.com/free-photo/3d-rendering-ventilation-system_23-2149281318.jpg?t=st=1712848688~exp=1712852288~hmac=eca6c674fc37ba4eeb398f075383057c51d70cc35c6a1f7039116b6e6a7fdcc4&w=826"})`,
                }}
              >
                <div className="container">
                  <div className="page-title-icon">
                    <i className="flaticon-air-conditioner hero__icon hero__icon1" />
                    <i className="flaticon-heating hero__icon hero__icon2" />
                    <i className="flaticon-vacuum-cleaner hero__icon hero__icon3" />
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="page-title text-center">
                        <h2 className="breadcrumb-title">{Projects2.name}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <>
                <div className="container pt-2 mb-3">
                  <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-12">
                      <div className="tm-sectiontitle text-center">
                        <h2 className="text-primary mt-3">{Projects2.name}</h2>
                        <span className="tm-sectiontitle-divider">
                          <img
                            alt="divider icon"
                            src="assets/images/ios4.png"
                          />
                        </span>

                        <p> {Projects2.description}</p>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3 mb-5">
                    {/* <div className="col-xl-12 col-lg-12 ">
                      <div className="tm-sectiontitle">
                        <h3 className="text-center" id="titling">
                          <b> RUNNING PROJECTS :-</b>
                        </h3>
                      </div>
                    </div> */}
                    <div className="col-xl-12 col-lg-12">
                      <Nav pills className="navtab-bg nav-justified">
                        {Projects1.map((data, index) => (
                          <>
                            <NavItem key={index} className="mt-2">
                              <NavLink
                                className={classnames({
                                  active: activeTab === index,
                                })}
                                onClick={() => toggleTab(index)}
                                style={{
                                  border: "1px #000 solid",
                                  borderRadius: "5px",
                                  padding: "5px",
                                  margin: "4px",
                                  cursor: "pointer",
                                }}
                              >
                                <b className="m-3">{data.name}</b>
                              </NavLink>
                            </NavItem>
                          </>
                        ))}

                        <TabContent
                          activeTab={activeTab}
                          className="pt-3 text-muted mt-4"
                        >
                          {Projects1.map((data, index) => {
                            return (
                              <TabPane key={index} tabId={index}>
                                <div className="row">
                                  {data.projects.map((datas, i) => {
                                    return (
                                      <>
                                        <div className="col-md-3 col-6 mt-3">
                                          <img
                                            key={i}
                                            src={api + datas.image}
                                            className="img-fluid"
                                            id="zoom"
                                            style={{
                                              width: "100%",
                                              height: "200px",
                                              borderRadius: "5px",
                                            }}
                                          />
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </TabPane>
                            );
                          })}
                        </TabContent>
                      </Nav>
                    </div>
                  </div>

                </div>
              </>
            </main>
            <Footer
              Services={Services}
              Products={Products1}
              Contact={Contact}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default AboutUs;
