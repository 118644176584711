import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Row, Col } from "reactstrap";
import axios from "axios";

function Header(props) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  const [activeTitle, setActiveTitle] = useState(null);

  const [activeTitle1, setActiveTitle1] = useState(null);

  const [show, setshow] = useState(false);

  const [show1, setshow1] = useState(false);

  const [show2, setshow2] = useState(false);

  const [show3, setshow3] = useState(false);

  const shows = show == true ? "block" : "none";

  const shows1 = show1 == true ? "block" : "none";

  const shows2 = show2 == true ? "block" : "none";

  const shows3 = show3 == true ? "block" : "none";

  const databyid = (data) => {
    sessionStorage.setItem("Ids", data._id);
    window.location.href = "/service";
  };

  const databyid1 = (data) => {
    setActiveTitle1(data.name);
    sessionStorage.setItem("Idss", data.productcategoryId);
    window.location.href = "/products";
  };

  const databyid3 = (data) => {
    sessionStorage.setItem("Idss", data._id);
    window.location.href = "/products";
  };

  const databyid2 = (data) => {
    sessionStorage.setItem("Idsss", data._id);
    window.location.href = "/project";
  };

  const userid = sessionStorage.getItem("token");

  const databyidS = () => {
    sessionStorage.clear();
    window.location.reload();
  };

  const [Products, setProducts] = useState([]);

  useEffect(() => {
    datas12();
  }, []);

  const datas12 = () => {
    const opd = props?.Products[0]?._id;
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/getprodyctById",
        { id: opd }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setProducts(res.data.product);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
          }
        }
      );
  };

  const datas1 = (data) => {
    setActiveTitle(data.title);
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/getprodyctById",
        { id: data._id },
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setProducts(res.data.product);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
          }
        }
      );
  };

  return (
    <div>
      <div className="ols">
        <div id="tm-home-area" className="tm-header">
          <div className="tm-header-topside">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-8">
                  <ul className="tm-header-topside-infoleft">
                    <li style={{ paddingLeft: "40px" }}>
                      <b>
                        <i className="ion-android-mail" />
                      </b>
                      <a href="mailto:ssairconditioning@gmail.com">
                        {props.Contact.email}
                      </a>
                    </li>
                    <li>
                      <b>
                        <i className="ion-android-call" />
                      </b>
                      <a href="tel:+18009156270">
                        +91 {props.Contact.contactNumber}
                      </a>
                    </li>
                    <li>
                      <b>
                        <i className="ion-clock" />
                      </b>
                      <span>Mon - Sat</span> : {props.Contact.fromTime} AM -{" "}
                      {props.Contact.toTime} PM
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4">
                  <ul className="tm-header-topside-inforight">
                    <li>
                      <li>
                        <a href={props.Contact.facebook}>
                          <i className="ion-social-facebook" />
                        </a>
                      </li>
                      <li>
                        <a href={props.Contact.twitter}>
                          <i className="ion-social-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href={props.Contact.instagram}>
                          <i className="ion-social-instagram-outline" />
                        </a>
                      </li>
                      <li style={{ paddingRight: "40px" }}>
                        <a href={props.Contact.youtubeLink}>
                          <i className="ion-social-youtube-outline" />
                        </a>
                      </li>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="tm-header-bottomside">
            <div className="container-fluid">
              <div className="tm-header-bottominner">
                <a className="tm-header-logo">
                  <img
                    src="assets/images/sslogo.png"
                    alt="oyoxo logo"
                    height="35px"
                    style={{ paddingLeft: "40px" }}
                  />
                </a>
                <nav className="tm-header-nav" style={{ display: "block" }}>
                  <ul>
                    <li className="">
                      <NavLink to="/"> Home</NavLink>
                    </li>
                    <li className="">
                      <NavLink to="/aboutUs">About Us</NavLink>
                    </li>
                    <li className="tm-header-nav-dropdown">
                      <a> Services</a>
                      <ul>
                        <li
                          style={{
                            borderBottom: "1px solid #ebebeb",
                            paddingBottom: "5px",
                            paddingTop: "5px",
                          }}
                        >
                          <NavLink to="/acmantance">
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            ></i>
                            Ac Maintance(AMC)
                          </NavLink>
                        </li>
                        {props.Services.map((data, i) => {
                          return (
                            <>
                              <li
                                style={{
                                  borderBottom: "1px solid #ebebeb",
                                  paddingBottom: "5px",
                                  paddingTop: "5px",
                                }}
                              >
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    databyid(data);
                                  }}
                                  key={i}
                                >
                                  <i
                                    className="fa fa-chevron-right"
                                    aria-hidden="true"
                                  ></i>
                                  {data.name}
                                </a>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    </li>
                    <li className="tm-header-nav-dropdown">
                      <a> Products</a>
                      <ul id="z1">
                        <Row>
                          <Col md="4">
                            {props.Products.map((data, i) => {
                              return (
                                <>
                                  <li
                                    style={{
                                      borderBottom: "1px solid #ebebeb",
                                      paddingBottom: "5px",
                                      paddingTop: "5px",
                                      backgroundColor:
                                        activeTitle === data.title
                                          ? "#2858A9"
                                          : "transparent",
                                    }}
                                  >
                                    <a
                                      style={{
                                        cursor: "pointer",
                                        color:
                                          activeTitle === data.title
                                            ? "white"
                                            : "",
                                      }}
                                      onClick={() => {
                                        datas1(data);
                                      }}
                                      key={i}
                                    >
                                      <i
                                        className="fa fa-chevron-right"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {data.title}
                                    </a>
                                  </li>
                                </>
                              );
                            })}
                          </Col>
                          <Col md="8">
                            <Row>
                              {Products.map((data, i) => {
                                return (
                                  <>
                                    <Col md="6">
                                      <li>
                                        <a
                                          style={{
                                            cursor: "pointer",
                                            color:
                                              activeTitle1 === data.name
                                                ? "#2858A9"
                                                : "black",
                                          }}
                                          onClick={() => {
                                            databyid1(data);
                                          }}
                                          key={i}
                                        >
                                          <i
                                            className="fa fa-chevron-right"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          {data.name}
                                        </a>
                                      </li>
                                    </Col>
                                  </>
                                );
                              })}
                            </Row>
                          </Col>
                        </Row>
                      </ul>
                    </li>
                    <li className="tm-header-nav-dropdown">
                      <a> Projects</a>
                      <ul>
                        {props.Projects.map((data, i) => {
                          return (
                            <>
                              <li
                                style={{
                                  borderBottom: "1px solid #ebebeb",
                                  paddingBottom: "5px",
                                  paddingTop: "5px",
                                }}
                              >
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    databyid2(data);
                                  }}
                                  key={i}
                                >
                                  <i
                                    className="fa fa-chevron-right"
                                    aria-hidden="true"
                                  ></i>
                                  {data.name}
                                </a>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    </li>
                    <li>
                      <NavLink to="/clients">Clients</NavLink>
                    </li>
                    <li>
                      <NavLink to="/financing">Financing</NavLink>
                    </li>
                    <li>
                      <NavLink to="/contact">Contact Us</NavLink>
                    </li>

                    <li
                      className="tm-header-nav-dropdowns"
                      id="pos"
                      style={{ paddingLeft: "30px" }}
                    >
                      <i
                        class="ion-android-contact"
                        style={{
                          color: "black",
                          fontSize: "25px",
                          paddingRight: "20px",
                        }}
                      ></i>
                      <ul style={{ left: "-50px" }}>
                        {userid === null ? (
                          <>
                            <li>
                              <NavLink
                                to="/cart"
                                style={{
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                }}
                              >
                                Login
                              </NavLink>
                            </li>
                          </>
                        ) : (
                          <></>
                        )}

                        {userid === null ? (
                          <></>
                        ) : (
                          <>
                            <li>
                              <NavLink
                                to="/cart"
                                style={{
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                }}
                              >
                                My Account
                              </NavLink>
                            </li>{" "}
                          </>
                        )}

                        {userid === null ? (
                          <></>
                        ) : (
                          <>
                            <li>
                              <a
                                style={{
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  databyidS();
                                }}
                              >
                                Log Out
                              </a>
                            </li>
                          </>
                        )}
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="old">
        <div id="tm-home-area" className="tm-header">
          <div className="tm-header-bottomside">
            <div className="container-fulid">
              <div className="tm-header-bottominner">
                <a className="tm-header-logo">
                  <img
                    src="assets/images/sslogo.png"
                    alt="oyoxo logo"
                    height="38px"
                  />
                </a>
                <div class="mean-push"></div>
                <nav class="tm-header-nav"></nav>
                <ul class="tm-header-icons tm-header-nav">
                  <li className="tm-header-nav-dropdown">
                    <i
                      class="ion-android-contact"
                      style={{
                        color: "black",
                        fontSize: "25px",
                        paddingRight: "20px",
                      }}
                    ></i>
                    <ul style={{ left: "-90px" }}>
                      {userid === null ? (
                        <>
                          <li>
                            <NavLink
                              to="/cart"
                              style={{
                                paddingTop: "5px",
                                paddingBottom: "5px",
                              }}
                            >
                              Login
                            </NavLink>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}

                      {userid === null ? (
                        <></>
                      ) : (
                        <>
                          <li>
                            <NavLink
                              to="/cart"
                              style={{
                                paddingTop: "5px",
                                paddingBottom: "5px",
                              }}
                            >
                              My Account
                            </NavLink>
                          </li>{" "}
                        </>
                      )}

                      {userid === null ? (
                        <></>
                      ) : (
                        <>
                          <li>
                            <a
                              style={{
                                paddingTop: "5px",
                                paddingBottom: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                databyidS();
                              }}
                            >
                              Log Out
                            </a>
                          </li>
                        </>
                      )}
                    </ul>
                  </li>
                </ul>

                <div className="tm-mobilenav mean-container">
                  <div className="mean-bar">
                    <a
                      onClick={() => {
                        setshow(!show);
                      }}
                      className="meanmenu-reveal"
                      style={{
                        background: "",
                        color: "",
                        right: 5,
                        left: "auto",
                      }}
                    >
                      <i className="ion-android-menu" />
                    </a>

                    <nav className="mean-nav ">
                      <ul style={{ display: `${shows}` }} className="pt-4 pb-4">
                        <li>
                          <NavLink to="/">Home</NavLink>
                        </li>
                        <li>
                          <NavLink to="/aboutUs">About Us</NavLink>
                        </li>
                        <li className="tm-header-nav-dropdown">
                          <a style={{ borderBottom: "1px solid #ebebeb" }}>
                            Services
                          </a>
                          <ul style={{ display: `${shows2}` }}>
                            <li style={{ borderBottom: "1px solid #ebebeb" }}>
                              <NavLink to="/acmantance">
                                <i
                                  className="fa fa-chevron-right"
                                  aria-hidden="true"
                                ></i>{" "}
                                Ac Maintance(AMC)
                              </NavLink>
                            </li>
                            {props.Services.map((data, i) => {
                              return (
                                <>
                                  <li
                                    style={{
                                      borderBottom: "1px solid #ebebeb",
                                    }}
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        databyid(data);
                                      }}
                                      key={i}
                                    >
                                      <i
                                        className="fa fa-chevron-right"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {data.name}
                                    </a>
                                  </li>
                                </>
                              );
                            })}
                          </ul>
                          <a
                            className="mean-expand"
                            onClick={() => {
                              setshow2(!show2);
                            }}
                            style={{ fontSize: 18 }}
                          >
                            +
                          </a>
                        </li>
                        <li className="tm-header-nav-dropdown">
                          <a style={{ borderBottom: "1px solid #ebebeb" }}>
                            Products
                          </a>
                          <ul style={{ display: `${shows1}` }}>
                            {props.Products.map((data, i) => {
                              return (
                                <>
                                  <li
                                    style={{
                                      borderBottom: "1px solid #ebebeb",
                                    }}
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        databyid3(data);
                                      }}
                                      key={i}
                                    >
                                      <i
                                        className="fa fa-chevron-right"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {data.title}
                                    </a>
                                  </li>
                                </>
                              );
                            })}
                          </ul>
                          <a
                            className="mean-expand"
                            onClick={() => {
                              setshow1(!show1);
                            }}
                            style={{ fontSize: 18 }}
                          >
                            +
                          </a>
                        </li>
                        <li className="tm-header-nav-dropdown">
                          <a style={{ borderBottom: "1px solid #ebebeb" }}>
                            Projects
                          </a>
                          <ul style={{ display: `${shows3}` }}>
                            {props.Projects.map((data, i) => {
                              return (
                                <>
                                  <li
                                    style={{
                                      borderBottom: "1px solid #ebebeb",
                                    }}
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        databyid2(data);
                                      }}
                                      key={i}
                                    >
                                      <i
                                        className="fa fa-chevron-right"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {data.name}
                                    </a>
                                  </li>
                                </>
                              );
                            })}
                          </ul>
                          <a
                            className="mean-expand"
                            onClick={() => {
                              setshow3(!show3);
                            }}
                            style={{ fontSize: 18 }}
                          >
                            +
                          </a>
                        </li>
                        <li>
                          <NavLink to="/clients">Clients</NavLink>
                        </li>
                        <li>
                          <NavLink to="/financing">Financing</NavLink>
                        </li>
                        <li>
                          <NavLink to="/contact">Contact Us</NavLink>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cp-floating-area d-none d-md-block zi-1100 p-relative d-none">
        <div class="cp-floating-action cp-bg-move-y">
          <a
            href="https://web.whatsapp.com/send?phone=9515733459&amp;text="
            target="_blank"
            class="sbutton whatsapp "
            tooltip="WhatsApp"
          >
            <img src="assets/images/wh2.png" alt="news" height="62px" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
