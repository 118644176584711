import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import classnames from "classnames";
import ReactPaginate from "react-paginate";

function AboutUs() {
  const [isLoading, setIsLoading] = useState(true);

  const [activeTab, setActiveTab] = useState("1");

  const [Shows, setShows] = useState(false);

  const toggleTab = (tabIndex) => {
    if (activeTab !== tabIndex) {
      setActiveTab(tabIndex);
    }
  };

  var navigate = useNavigate();

  useEffect(() => {
    datas();
    const userid = sessionStorage.getItem("token");
    if (userid == null) {
      navigate("/login");
    }
  }, []);

  const [Contact, setContact] = useState([]);
  const [Projects, setProjects] = useState([]);
  const [Products1, setProducts1] = useState([]);
  const [Services, setServices] = useState([]);

  const datas = () => {
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setContact(res.data.contactus[0]);
            setProducts1(res.data.productcategorys);
            setProjects(res.data.projectcategorys);
            setServices(res.data.services);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  const token = sessionStorage.getItem("token");
  const userid = sessionStorage.getItem("userid");

  useEffect(() => {
    GetBookings();
  }, []);

  const [PendingBookings, setPendingBookings] = useState([]);
  const [CompleatedBookings, setCompleatedBookings] = useState([]);

  const GetBookings = () => {
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/booking/getuserbookings",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setPendingBookings(res.data.pending);
            setCompleatedBookings(res.data.confirmed);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
          }
        }
      );
  };

  const [form, setform] = useState([]);

  const formsubmit = (e) => {
    e.preventDefault();
    Add();
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);

    setShows(true);
  };

  const Add = () => {
    const dataArray = {
      name: form.name,
      email: form.email,
      phone: form.phone,
    };

    axios
      .put(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/auth/edituserprofile",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            GetProfile();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  useEffect(() => {
    GetProfile();
  }, []);

  const GetProfile = () => {
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/auth/getprofile",
        { userId: userid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setform(res.data.user);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
          }
        }
      );
  };

  const [listPerPage] = useState(3);
  const [pageNumber, setPageNumber] = useState(0);

  const pagesVisited = pageNumber * listPerPage;
  const lists = PendingBookings.slice(pagesVisited, pagesVisited + listPerPage);
  const pageCount = Math.ceil(PendingBookings.length / listPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [listPerPage1] = useState(3);
  const [pageNumber1, setPageNumber1] = useState(0);

  const pagesVisited1 = pageNumber1 * listPerPage1;
  const lists1 = CompleatedBookings.slice(
    pagesVisited1,
    pagesVisited1 + listPerPage1
  );

  const pageCount1 = Math.ceil(CompleatedBookings.length / listPerPage1);
  const changePage1 = ({ selected1 }) => {
    setPageNumber1(selected1);
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
            <span class="loader"></span>
          </div>
        </>
      ) : (
        <>
          <div className="wrapper">
            <Header
              Services={Services}
              Products={Products1}
              Projects={Projects}
              Contact={Contact}
            />
            <main
              class="page-content"
              id="fld"
              style={{ background: "#F7F7F7" }}
            >
              <div
                className="page-title-area pt-210 pb-180"
                style={{
                  backgroundImage: `url(${"https://img.freepik.com/free-photo/3d-rendering-ventilation-system_23-2149281318.jpg?t=st=1712848688~exp=1712852288~hmac=eca6c674fc37ba4eeb398f075383057c51d70cc35c6a1f7039116b6e6a7fdcc4&w=826"})`,
                }}
              >
                <div className="container">
                  <div className="page-title-icon">
                    <i className="flaticon-air-conditioner hero__icon hero__icon1" />
                    <i className="flaticon-heating hero__icon hero__icon2" />
                    <i className="flaticon-vacuum-cleaner hero__icon hero__icon3" />
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="page-title text-center">
                        <h2 className="breadcrumb-title"> My Account</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="tm-section tm-my-account-area tm-padding-section">
                  <div className="container">
                    <div className="tm-myaccount">
                      <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-8">
                          <div className="tm-sectiontitle text-center">
                            <h3 className="pt-2 text-primary" id="titling">
                              My Account
                            </h3>
                            <span className="tm-sectiontitle-divider">
                              <img
                                alt="divider icon"
                                src="assets/images/ios4.png"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <Row className="mt-4 mb-5">
                        <Col md={4} className="mb-4">
                          <Card>
                            <CardBody>
                              <div className="cp-news-widget-post-item">
                                <div className="cp-news-widget-post-img">
                                  <img
                                    src="assets/imgs.png"
                                    alt="news"
                                    height="100px"
                                  />
                                </div>
                                <div className="cp-news-widget-post-text">
                                  <span className="cp-news-widget-post-text">
                                    Hello
                                  </span>
                                  <h4>{form.name}</h4>
                                </div>
                              </div>
                            </CardBody>
                          </Card>

                          <Card className="mt-3">
                            <CardBody>
                              <Nav vertical>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab === "1",
                                    })}
                                    onClick={() => {
                                      toggleTab("1");
                                    }}
                                  >
                                    <i
                                      className="fa fa-chevron-right"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Update Profile
                                  </NavLink>
                                </NavItem>
                                <hr></hr>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab === "2",
                                    })}
                                    onClick={() => {
                                      toggleTab("2");
                                    }}
                                  >
                                    <i
                                      className="fa fa-chevron-right"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Pending Services
                                  </NavLink>{" "}
                                </NavItem>
                                <hr></hr>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab === "3",
                                    })}
                                    onClick={() => {
                                      toggleTab("3");
                                    }}
                                  >
                                    <i
                                      className="fa fa-chevron-right"
                                      aria-hidden="true"
                                    ></i>
                                    Completed Services
                                  </NavLink>
                                </NavItem>
                              </Nav>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md={8}>
                          <TabContent
                            activeTab={activeTab}
                            className="text-muted"
                          >
                            <TabPane tabId="1">
                              <Card>
                                <Row>
                                  <Col md={12}>
                                    <Card>
                                      <CardBody>
                                        <form
                                          onSubmit={(e) => {
                                            formsubmit(e);
                                          }}
                                        >
                                          <div class="row gy-3 overflow-hidden">
                                            <h5 className="text-primary">
                                              Update Profile
                                            </h5>
                                            <div className="col-6 mb-3">
                                              <label
                                                htmlFor="email"
                                                className="form-label"
                                              >
                                                Full Name{" "}
                                              </label>

                                              <input
                                                type="text"
                                                className="form-control"
                                                name="name"
                                                required
                                                placeholder="Enter Full Name"
                                                onChange={(e) => {
                                                  handleChange(e);
                                                }}
                                                value={form.name}
                                              />
                                            </div>
                                            <div className="col-6 mb-3">
                                              <label
                                                htmlFor="email"
                                                className="form-label"
                                              >
                                                Email{" "}
                                              </label>
                                              <input
                                                type="email"
                                                className="form-control"
                                                name="email"
                                                required
                                                placeholder="Enter Email"
                                                onChange={(e) => {
                                                  handleChange(e);
                                                }}
                                                value={form.email}
                                              />
                                            </div>
                                            <div className="col-12 mb-3">
                                              <label
                                                htmlFor="email"
                                                className="form-label"
                                              >
                                                Mobile Number{" "}
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                name="phone"
                                                required
                                                maxlength="10"
                                                minLength="10"
                                                pattern="[0-9]{10}"
                                                placeholder="Enter Mobile Number"
                                                disabled
                                                onChange={(e) => {
                                                  handleChange(e);
                                                }}
                                                value={form.phone}
                                                onKeyPress={(e) => {
                                                  // Allow only numeric input
                                                  const charCode = e.which
                                                    ? e.which
                                                    : e.keyCode;
                                                  if (
                                                    charCode < 48 ||
                                                    charCode > 57
                                                  ) {
                                                    e.preventDefault();
                                                  }
                                                }}
                                              />
                                            </div>

                                            <div class="col-12">
                                              <div style={{ float: "right" }}>
                                                {Shows == true ? (
                                                  <>
                                                    <button
                                                      style={{
                                                        width: "100%",
                                                        marginTop: 6,
                                                        backgroundColor:
                                                          "#2858A9",
                                                        border: 0,
                                                        color: "white",
                                                        height: "35px",
                                                      }}
                                                      type="submit"
                                                    >
                                                      Update Profile
                                                    </button>
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                </Row>
                              </Card>
                            </TabPane>
                            <TabPane tabId="2">
                              {lists.length == 0 ? (
                                <>
                                  <Card style={{ height: "400px" }}>
                                    <CardBody>
                                      <h5
                                        style={{
                                          paddingTop: "150px",
                                          textAlign: "center",
                                        }}
                                      >
                                        No Data...
                                      </h5>
                                    </CardBody>
                                  </Card>
                                </>
                              ) : (
                                <>
                                  {lists.map((data, i) => (
                                    <>
                                      <div className="card mb-2" key={i}>
                                        <div className="card-body">
                                          <div>
                                            <h5>
                                              Service Id :
                                              <span
                                                style={{ color: "#2858A9" }}
                                              >
                                                {data.bookingId}
                                              </span>
                                              | Service date :
                                              <span
                                                style={{ color: "#2858A9" }}
                                              >
                                                {data.bookingDate}/
                                                {data.bookingTime}
                                              </span>
                                            </h5>
                                          </div>
                                          <hr></hr>
                                          <div className="row">
                                            {data.services.map((datas, is) => (
                                              <>
                                                <div
                                                  className="col-md-12"
                                                  key={is}
                                                >
                                                  <div className="row mt-3 ">
                                                    <div className="col-6">
                                                      <h5
                                                        style={{
                                                          color: "#2858A9",
                                                        }}
                                                      >
                                                        <span className="text-muted">
                                                          Service Name :{" "}
                                                        </span>
                                                        {datas.name}
                                                      </h5>
                                                    </div>
                                                    <div className="col-6">
                                                      <h5
                                                        className="mt-20"
                                                        style={{
                                                          float: "right",
                                                        }}
                                                      >
                                                        Status :
                                                        <span
                                                          style={{
                                                            fontSize: "14px",
                                                          }}
                                                        >
                                                          {data.status ==
                                                          "completed" ? (
                                                            <>
                                                              <span className="badge bg-success">
                                                                {data.status}
                                                              </span>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <span className="badge bg-warning">
                                                                {data.status}
                                                              </span>
                                                            </>
                                                          )}
                                                        </span>
                                                      </h5>
                                                    </div>
                                                  </div>

                                                  <div className="row mt-3">
                                                    <div className="col-6">
                                                      <h6
                                                        style={{
                                                          color: "#2858A9",
                                                        }}
                                                      >
                                                        <span className="text-muted">
                                                          Quantity :{" "}
                                                        </span>
                                                        {datas.quantity}
                                                      </h6>
                                                    </div>
                                                    <div className="col-6">
                                                      <h6
                                                        style={{
                                                          color: "#2858A9",
                                                        }}
                                                      >
                                                        <span className="text-muted">
                                                          Total Amount :{" "}
                                                        </span>
                                                        ₹ {datas.price} /-
                                                      </h6>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ))}
                                  <div
                                    className="mt-3"
                                    style={{ float: "right" }}
                                  >
                                    <ReactPaginate
                                      previousLabel={"Previous"}
                                      nextLabel={"Next"}
                                      pageCount={pageCount}
                                      onPageChange={changePage}
                                      containerClassName={"pagination"}
                                      previousLinkClassName={"previousBttn"}
                                      nextLinkClassName={"nextBttn"}
                                      disabledClassName={"disabled"}
                                      activeClassName={"active"}
                                      total={lists.length}
                                    />
                                  </div>
                                </>
                              )}
                            </TabPane>
                            <TabPane tabId="3">
                              {lists1.length == 0 ? (
                                <>
                                  <Card style={{ height: "400px" }}>
                                    <CardBody>
                                      <h5
                                        style={{
                                          paddingTop: "150px",
                                          textAlign: "center",
                                        }}
                                      >
                                        No Data...
                                      </h5>
                                    </CardBody>
                                  </Card>
                                </>
                              ) : (
                                <>
                                  {lists1.map((data, i) => (
                                    <>
                                      <div className="card mb-2" key={i}>
                                        <div className="card-body">
                                          <div>
                                            <h5>
                                              Service Id :
                                              <span
                                                style={{ color: "#2858A9" }}
                                              >
                                                {data.bookingId}
                                              </span>{" "}
                                              | Service date :
                                              <span
                                                style={{ color: "#2858A9" }}
                                              >
                                                {data.bookingDate}/
                                                {data.bookingTime}
                                              </span>
                                            </h5>
                                          </div>
                                          <hr></hr>
                                          <div className="row">
                                            {data.services.map((datas, is) => (
                                              <>
                                                <div
                                                  className="col-md-12"
                                                  key={is}
                                                >
                                                  <div className="row mt-3 ">
                                                    <div className="col-6">
                                                      <h5
                                                        style={{
                                                          color: "#2858A9",
                                                        }}
                                                      >
                                                        <span className="text-muted">
                                                          Service Name :{" "}
                                                        </span>
                                                        {datas.name}
                                                      </h5>
                                                    </div>
                                                    <div className="col-6">
                                                      <h5
                                                        className="mt-20"
                                                        style={{
                                                          float: "right",
                                                        }}
                                                      >
                                                        Status :
                                                        <span
                                                          style={{
                                                            fontSize: "14px",
                                                          }}
                                                        >
                                                          {data.status ==
                                                          "completed" ? (
                                                            <>
                                                              <span className="badge bg-success">
                                                                {data.status}
                                                              </span>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <span className="badge bg-warning">
                                                                {data.status}
                                                              </span>
                                                            </>
                                                          )}
                                                        </span>
                                                      </h5>
                                                    </div>
                                                  </div>

                                                  <div className="row mt-3">
                                                    <div className="col-6">
                                                      <h6
                                                        style={{
                                                          color: "#2858A9",
                                                        }}
                                                      >
                                                        <span className="text-muted">
                                                          Quantity :{" "}
                                                        </span>
                                                        {datas.quantity}
                                                      </h6>
                                                    </div>
                                                    <div className="col-6">
                                                      <h6
                                                        style={{
                                                          color: "#2858A9",
                                                        }}
                                                      >
                                                        <span className="text-muted">
                                                          Total Amount :{" "}
                                                        </span>
                                                        ₹ {datas.price} /-
                                                      </h6>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ))}
                                  <div
                                    className="mt-3"
                                    style={{ float: "right" }}
                                  >
                                    <ReactPaginate
                                      previousLabel={"Previous"}
                                      nextLabel={"Next"}
                                      pageCount={pageCount1}
                                      onPageChange={changePage1}
                                      containerClassName={"pagination"}
                                      previousLinkClassName={"previousBttn"}
                                      nextLinkClassName={"nextBttn"}
                                      disabledClassName={"disabled"}
                                      activeClassName={"active"}
                                      total={lists1.length}
                                    />
                                  </div>{" "}
                                </>
                              )}
                            </TabPane>
                          </TabContent>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <ToastContainer />
            <Footer
              Services={Services}
              Products={Products1}
              Contact={Contact}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default AboutUs;
