import React from "react";
import { NavLink } from "react-router-dom";

function Footer(props) {
  const databyid = (data) => {
    sessionStorage.setItem("Ids", data._id);
    window.location.href = "/service";
  };

  const databyid1 = (data) => {
    sessionStorage.setItem("Idss", data._id);
    window.location.href = "/products";
  };

  return (
    <div>
      <footer className="tm-footer">
        <div
          className="tm-footer-topside pt-5 pb-4"
          data-bgimage="assets/images/bg/footer-bg.png"
          style={{ background: "black" }}
        >
          <div className="container">
            <div className="widgets widgets-footer row">
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-widget widget-info">
                  <a className="widget-info-logo">
                    <img src="assets/LogoWhite1.png" alt="white logo" />
                  </a>
                  <p style={{ color: "white" }} className="pt-3 pb-3">
                    SS Air Conditioning Solutions offers comprehensive Annual
                    Maintenance Contracts (AMC)  <NavLink to="/aboutUs" style={{color:"#28589E"}}>Read More...</NavLink>
                  </p>

                  <ul>
                    <li>
                      <a href={props.Contact.facebook}>
                        <i className="ion-social-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href={props.Contact.twitter}>
                        <i className="ion-social-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href={props.Contact.instagram}>
                        <i className="ion-social-instagram-outline" />
                      </a>
                    </li>
                    <li>
                      <a href={props.Contact.youtubeLink}>
                        <i className="ion-social-youtube-outline" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-widget widget-quicklinks">
                  <h4 className="widget-title">Services</h4>
                  <ul>
                    <li>
                      <a>Ac Maintance(AMC)</a>
                    </li>
                    {props.Services.map((data, i) => {
                      return (
                        <>
                          <li>
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                databyid(data);
                              }}
                              key={i}
                            >
                              {data.name}
                            </a>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-widget widget-quicklinks">
                  <h4 className="widget-title">Products</h4>
                  <ul>
                    {props.Products.map((data, i) => {
                      return (
                        <>
                          <li>
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                databyid1(data);
                              }}
                              key={i}
                            >
                              {data.title}
                            </a>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-widget widget-hours">
                  <h4 className="widget-title">Opening Hours</h4>
                  <div className="row">
                    <div className="col-md-4 col-4">
                      <ul>
                        <span style={{ float: "right" }}>: </span>
                        <li>Email</li>{" "}
                        <span style={{ float: "right" }}>: </span>
                        <li>Mon - Sat</li>
                        <span style={{ float: "right" }}>: </span>
                        <li>Contact Us </li>
                        <span style={{ float: "right" }}>: </span>
                        <li>Address </li>
                      </ul>
                    </div>
                    <div className="col-md-8 col-8">
                      <ul>
                        <li>{props.Contact.email}</li>
                        <li>
                          {props.Contact.fromTime} AM - {props.Contact.toTime}{" "}
                          PM
                        </li>
                        <li>+91 {props.Contact.contactNumber}</li>
                        <li>{props.Contact.address}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="tm-footer-bottomside"
          style={{ background: "black", borderTop: "1px solid white" }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <p className="tm-footer-copyright text-center">
                  © 2024.{" "}
                  <a style={{ color: "#2858A9" }} href="#">
                    SS Airconditioning Solutions.
                  </a>{" "}
                  All Rights Reserved - Designed by
                  <a href="https://digitalraiz.com/" target="_blank">
                    {" "}
                    Digitalraiz
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
