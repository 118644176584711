import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function AboutUs() {
  const [isLoading, setIsLoading] = useState(true);

  const phone = sessionStorage.getItem("mobilenos");

  useEffect(() => {
    datas();
  }, []);

  const [Contact, setContact] = useState([]);
  const [Projects, setProjects] = useState([]);
  const [Products1, setProducts1] = useState([]);
  const [Services, setServices] = useState([]);

  const datas = () => {
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setContact(res.data.contactus[0]);
            setProducts1(res.data.productcategorys);
            setProjects(res.data.projectcategorys);
            setServices(res.data.services);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  const navigate = useNavigate();

  const [form, setform] = useState([]);

  const formsubmit = (e) => {
    e.preventDefault();
    Add();
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const token = sessionStorage.getItem("token");

  const Add = () => {
    const dataArray = {
      name: form.name,
      email: form.email,
      phone: phone,
    };

    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/auth/userRegistration",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            setform({
              name: "",
              email: "",
            });
            navigate("/");
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
            <span class="loader"></span>
          </div>
        </>
      ) : (
        <>
          <div className="wrapper">
            <Header
              Services={Services}
              Products={Products1}
              Projects={Projects}
              Contact={Contact}
            />

            <main class="page-content" id="fld">
              <section class="bg-grey p-3 p-md-4 p-xl-5">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-12 col-xxl-11">
                      <div class="card border-light-subtle shadow-sm">
                        <div class="row g-0">
                          <div class="col-12 col-md-6">
                            <img
                              class="img-fluid rounded-start w-100 h-100 object-fit-cover"
                              loading="lazy"
                              src="https://tscair.com/wp-content/uploads/2021/02/4311001-1.jpg"
                              alt="Welcome back you've been missed!"
                            />
                          </div>
                          <div class="col-12 col-md-6 d-flex align-items-center justify-content-center">
                            <div
                              class="col-12 col-lg-11 col-xl-10"
                              data-wow-delay="900ms"
                              data-wow-duration="3000ms"
                              data-aos="fade-left"
                            >
                              <div class="card-body p-3 p-md-4 p-xl-5">
                                <div class="row">
                                  <div class="col-12">
                                    <div class="mb-5">
                                      <div class="text-center mb-4">
                                        <h2 className="text-primary">
                                          {" "}
                                          Sign Up
                                        </h2>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <form
                                  onSubmit={(e) => {
                                    formsubmit(e);
                                  }}
                                >
                                  <div class="row gy-3 overflow-hidden">
                                    <div className="col-12 mb-3">
                                      <label
                                        htmlFor="email"
                                        className="form-label"
                                      >
                                        Full Name{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        required
                                        placeholder="Enter Full Name"
                                        onChange={(e) => {
                                          handleChange(e);
                                        }}
                                        value={form.name}
                                      />
                                    </div>
                                    <div className="col-12 mb-3">
                                      <label
                                        htmlFor="email"
                                        className="form-label"
                                      >
                                        Email{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        required
                                        placeholder="Enter Email"
                                        onChange={(e) => {
                                          handleChange(e);
                                        }}
                                        value={form.email}
                                      />
                                    </div>
                                    <div className="col-12 mb-3">
                                      <label
                                        htmlFor="email"
                                        className="form-label"
                                      >
                                        Mobile Number{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="phone"
                                        required
                                        maxlength="10"
                                        minLength="10"
                                        pattern="[0-9]{10}"
                                        placeholder="Enter Mobile Number"
                                        disabled
                                        // onChange={(e) => {
                                        //   handleChange(e);
                                        // }}
                                        value={phone}
                                        onKeyPress={(e) => {
                                          // Allow only numeric input
                                          const charCode = e.which
                                            ? e.which
                                            : e.keyCode;
                                          if (charCode < 48 || charCode > 57) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                    </div>

                                    <div class="col-12">
                                      <div class="d-grid">
                                        <button
                                          class="btn btn-primary btn-lg w-100 mt-4"
                                          type="submit"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
            <ToastContainer />
            <Footer
              Services={Services}
              Products={Products1}
              Contact={Contact}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default AboutUs;
