import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

function AboutUs() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    datas();
  }, []);

  const [Contact, setContact] = useState([]);
  const [Projects, setProjects] = useState([]);
  const [Products1, setProducts1] = useState([]);
  const [Services, setServices] = useState([]);

  const [HomeContant, setHomeContant] = useState([]);

  const datas = () => {
    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setHomeContant(res.data.homecontents[0]);

            setContact(res.data.contactus[0]);
            setProducts1(res.data.productcategorys);
            setProjects(res.data.projectcategorys);
            setServices(res.data.services);

            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  const [form, setform] = useState([]);

  const formsubmit = (e) => {
    e.preventDefault();
    Add();
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const Add = () => {
    const dataArray = {
      name: form.name,
      email: form.email,
      phoneNumber: form.phoneNumber,
      subject: form.subject,
      description: form.description,
    };

    axios
      .post(
        "https://api.ssairconditioners.com/v1/ssairconditionsapi/web/enquiry/addenquiry",
        dataArray
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            setform({
              name: "",
              email: "",
              phoneNumber: "",
              subject: "",
              description: "",
            });
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div class="centers">
            <span class="loader"></span>
          </div>
        </>
      ) : (
        <>
          <div className="wrapper">
            <Header
              Services={Services}
              Products={Products1}
              Projects={Projects}
              Contact={Contact}
            />

            <main
              className="page-content"
              id="fld"
              style={{ background: "#F7F7F7" }}
            >
              <div className="container pt-2 mb-3">
                <div className="row justify-content-center">
                  <div className="col-xl-12 col-lg-12">
                    <div className="tm-sectiontitle text-center">
                      <h2 className="text-primary mt-3">
                        {" "}
                        {HomeContant.getintouchTitle}
                      </h2>
                      <span className="tm-sectiontitle-divider">
                        <img alt="divider icon" src="assets/images/ios4.png" />
                      </span>
                      <p>{HomeContant.getintouchDescription}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2">
                      <div
                        className="tm-contact-block text-center "
                        style={{ height: "280px" }}
                      >
                        <span className="tm-contact-block-icon">
                          <img alt="icon" src="assets/images/ioc1.png" />
                        </span>
                        <div className="tm-contact-block-content">
                          <h5>Address</h5>
                          <p>{Contact.address}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2">
                      <div
                        className="tm-contact-block text-center "
                        style={{ height: "280px" }}
                      >
                        <span className="tm-contact-block-icon">
                          <img alt="icon" src="assets/images/ios2.png" />
                        </span>
                        <div className="tm-contact-block-content">
                          <h5>Phone</h5>
                          <p>
                            <a href="tel:+18009156270">
                              +91 {Contact.contactNumber}
                            </a>
                          </p>
                          <p>
                            <a href="tel:+18009156272">
                              +91 {Contact.contactNumber}
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2">
                      <div
                        className="tm-contact-block text-center "
                        style={{ height: "280px" }}
                      >
                        <span className="tm-contact-block-icon">
                          <img alt="icon" src="assets/images/ios3.png" />
                        </span>
                        <div className="tm-contact-block-content">
                          <h5>Email</h5>
                          <p>
                            Email:{" "}
                            <a href="mailto:sairconditioning@gmail.com ">
                              {Contact.email}{" "}
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pb-2 mt-3" id="rtx">
                <div className="container-fluid ">
                  <div className="row justify-content-center pt-5">
                    <div className="col-lg-6">
                      <div id="google-map" className="google-map pb-3">
                        <iframe
                          src={Contact.maps}
                          frameBorder={0}
                          scrolling="no"
                          style={{
                            height: "99%",
                            width: "100%",
                            borderRadius: "20px",
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 pb-4">
                      <div style={{ border: "1px solid white" }}>
                        <h4
                          className="text-center text-white  pt-3"
                          style={{
                            borderBottom: "1px solid white",
                            borderRadius: "10px",
                          }}
                        >
                          {HomeContant.getintouchTitle}
                        </h4>

                        <div className="m-3">
                          <form
                            onSubmit={(e) => {
                              formsubmit(e);
                            }}
                          >
                            <div className="row mt-3">
                              <div className="col-md-6 col-12 ">
                                <div className="mb-3">
                                  <label className="text-white">Name</label>{" "}
                                  <span className="text-danger">*</span>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Your Name"
                                    name="name"
                                    required
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    value={form.name}
                                  />
                                </div>{" "}
                              </div>{" "}
                              <div className="col-md-6 col-12">
                                <div className="mb-3">
                                  <label className="text-white">Email</label>{" "}
                                  <span className="text-danger">*</span>
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter Your Email Id"
                                    name="email"
                                    required
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    value={form.email}
                                  />
                                </div>
                              </div>{" "}
                              <div className="col-md-6 col-12">
                                <div className="mb-3">
                                  <label className="text-white">
                                    Phone Number
                                  </label>
                                  <span className="text-danger">*</span>
                                  <input
                                    type="text"
                                    placeholder="Enter Your Phone Number"
                                    name="phoneNumber"
                                    className="form-control"
                                    required
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    maxlength="10"
                                    minLength="10"
                                    pattern="[0-9]{10}"
                                    value={form.phoneNumber}
                                    onKeyPress={(e) => {
                                      // Allow only numeric input
                                      const charCode = e.which
                                        ? e.which
                                        : e.keyCode;
                                      if (charCode < 48 || charCode > 57) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                </div>
                              </div>{" "}
                              <div className="col-md-6 col-12">
                                <div className="mb-3">
                                  <label className="text-white">Service </label>
                                  <span className="text-danger">*</span>
                                  <select
                                    value={form.subject}
                                    name="subject"
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    className="form-select"
                                  >
                                    <option value="">Select</option>
                                    {Services.map((data, key) => {
                                      return (
                                        <option key={key} value={data.name}>
                                          {data.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>{" "}
                              <div className="col-12">
                                <div className="mb-3">
                                  <label className="text-white">
                                    Description
                                  </label>
                                  <textarea
                                    cols={30}
                                    rows={3}
                                    placeholder="Description"
                                    className="form-control"
                                    name="description"
                                    required
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    value={form.description}
                                  />
                                </div>
                                <div style={{ float: "right" }}>
                                  <button
                                    type="submit"
                                    style={{
                                      marginTop: 6,
                                      backgroundColor: "#2858A9",
                                      border: 0,
                                      color: "white",
                                      height: "35px",
                                    }}
                                  >
                                    Submit Now
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                          <p className="form-messages" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <ToastContainer />
            <Footer
              Services={Services}
              Products={Products1}
              Contact={Contact}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default AboutUs;
